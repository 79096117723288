import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  MainContentBox,
  Table,
  Paper,
  Typography,
  Loader,
  Checkbox,
  Button,
  useSnack,
  FullScreenLoader,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { useGetGrunnlagsdataRevisorattest } from '../../hooks/useGetGrunnlagsdataRevisorattest';
import { useAttestation } from '../../hooks/useAttestSurchargeReport';
import {
  ProblemDetails,
  instanceOf,
  SelectedAktor,
  RoleType,
} from '../../../../utils/types';
import { useSigning } from '../../hooks/useSigning';
import useAppData from '../../../../hooks/useAppData';

import { AttestationValues } from './types';
import AuditingHeader from './components/auditingHeader';
import { getTableData, getTableHead } from './utils';

const isAuditor = (selectedAktor: SelectedAktor | null): boolean => {
  return selectedAktor?.roles?.includes(RoleType.Auditor) === true;
};

const LoaderView: FC = () => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" p={4}>
      <Loader color="inherit" size="small" />
    </Box>
  );
};

const AttestationPage: FC = () => {
  const location = useLocation();
  const { addSnack } = useSnack();
  const { t } = useTranslation();
  const { selectedAktor } = useAppData();
  const [checked, setChecked] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const values = location.state as AttestationValues;
  const { year, organizationName, organizationNumber, attested } = values;
  const { attest, isLoading: attestationIsLoading } = useAttestation();
  const { sign, isLoading: isSigningLoading } = useSigning();
  const {
    auditorBasis,
    isLoading: isAuditorbasisLoading,
  } = useGetGrunnlagsdataRevisorattest(organizationNumber, year);

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onSign = useCallback(() => {
    sign(
      { organisasjonsnummer: organizationNumber, ar: year },
      {
        onSuccess: (data) => {
          setIsWaiting(false);
          if (instanceOf<{ url: string }>(data)) {
            window.location.href = data.url;
          }
        },
        onError: (err) => {
          setIsWaiting(false);
          if (instanceOf<ProblemDetails>(err)) {
            if (err.status === 403) {
              addSnack(t('surcharge.signing.failed.notAuthorized'), {
                variant: 'error',
              });
              return;
            }
            addSnack(t('surcharge.signing.failed.default'), {
              variant: 'error',
            });
          }
        },
      }
    );
  }, [values, sign]);

  const onSave = useCallback(() => {
    attest(
      { organizationNumber, year },
      {
        onSuccess: () => {
          setIsWaiting(true);
          // Waiting 2 seconds to make sure the receipt exists before initiating signing request
          setTimeout(onSign, 2500);
        },
        onError: (err) => {
          if (instanceOf<ProblemDetails>(err)) {
            if (err.status === 403) {
              addSnack(t('surcharge.attestation.saveFailed.notAuthorized'), {
                variant: 'error',
              });
              return;
            }
            addSnack(t('surcharge.attestation.saveFailed.default'), {
              variant: 'error',
            });
          }
        },
      }
    );
  }, [values, attest]);

  const heads = useMemo(() => getTableHead(t), [t]);
  const rows = useMemo(() => {
    if (!isAuditorbasisLoading && auditorBasis != undefined) {
      return getTableData(t, auditorBasis);
    }
    return [];
  }, [isAuditorbasisLoading, auditorBasis]);

  return (
    <Fragment>
      <MainContentBox>
        <Box mr={8}>
          <Paper>
            <AuditingHeader year={year} attested={attested} />
            <Box padding={4}>
              <Typography paragraph variant="h1">
                {t('surcharge.attestation.header')} {year}
              </Typography>
              <Typography paragraph variant="h4">
                {t('surcharge.attestation.name')} : {organizationName}
              </Typography>
              <Typography paragraph variant="h4">
                {t('surcharge.attestation.organizationNumber')} :{' '}
                {organizationNumber}
              </Typography>
              <Typography paragraph>
                {t('surcharge.attestation.regulationText')}
              </Typography>
              <Typography paragraph>
                {t('surcharge.attestation.regulationText2')}
              </Typography>
              <Typography paragraph>
                {t('surcharge.attestation.regulationText3')}
              </Typography>
              {isAuditorbasisLoading ? (
                <LoaderView />
              ) : (
                <Fragment>
                  <Table
                    alternating
                    dense
                    disablePagination
                    headCells={heads}
                    rows={rows}
                    shadow="none"
                  />
                  {!isAuditor(selectedAktor) && (
                    <Box paddingTop={4}>
                      <Typography variant="body2" color="error">
                        {t('surcharge.attestation.unauthorized')}
                      </Typography>
                    </Box>
                  )}
                  <Box paddingTop={4}>
                    <Checkbox
                      disabled={!isAuditor(selectedAktor)}
                      width="fullWidth"
                      label={t('surcharge.attestation.confirmationText')}
                      value={checked}
                      onChange={handleCheckedChange}
                    />
                  </Box>

                  <Grid container spacing={4} paddingTop={5}>
                    <Grid item xs={12} sm="auto">
                      <RouterLink
                        to={{
                          pathname: '/surcharge/overview',
                        }}
                      >
                        <Button variant="secondary" leftIcon="close">
                          {t('cancel')}
                        </Button>
                      </RouterLink>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <Button
                        variant="primary"
                        rightIcon="arrow_right"
                        disabled={
                          attestationIsLoading ||
                          isSigningLoading ||
                          isWaiting ||
                          !checked
                        }
                        onClick={onSave}
                      >
                        {t('surcharge.attestation.confirmAndSend')}
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Box>
          </Paper>
        </Box>
      </MainContentBox>
      <FullScreenLoader
        open={attestationIsLoading || isSigningLoading || isWaiting}
      >
        <Box alignItems="center" display="flex" flexDirection="column">
          <Typography component="p" variant="h4">
            {t('surcharge.attestation.loading.header')}
          </Typography>
          <Typography>{t('surcharge.attestation.loading.body')}</Typography>
        </Box>
      </FullScreenLoader>
    </Fragment>
  );
};

export { AttestationPage };
