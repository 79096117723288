import { FC, Fragment } from 'react';
import {
  Grid,
  Box,
  Paper,
  Typography,
  Link,
  Icon,
  useEnovaContext,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetReportedReceipt } from '../../hooks/useGetReportedReceipt';

import { ReceiptValues, SelectedTerm } from './types';

type Props = {
  version: number | null;
  receipt: ReceiptValues;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<ReceiptValues | null | undefined>
  >;
};

const VersionCard: FC<Props> = ({
  version,
  receipt,
  setSelectedVersion: setSelectedReceipt,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { theme } = useEnovaContext();
  const selectedValues = location.state as SelectedTerm;
  const {
    organizationNumber,
    organizationName,
    sequence,
    year,
    deadline,
  } = selectedValues;
  const { invoice: reportedReceipt } = useGetReportedReceipt(
    organizationNumber,
    year,
    sequence,
    receipt.version
  );

  const updateSelectedVersion = (updateReceipt: ReceiptValues) => {
    const updatedState = {
      ...updateReceipt,
      organizationNumber: organizationNumber,
      organizationName: organizationName,
      year: year,
      sequence: sequence,
      deadline: deadline,
    };

    setSelectedReceipt(updatedState);
  };

  const handleClick = (item: ReceiptValues) => {
    updateSelectedVersion(item);
  };

  const selectedBox = {
    borderRadius: '5px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  };

  return (
    <Grid item xs={12} key={receipt.version}>
      <Paper className="">
        <Box
          padding={3}
          style={version == receipt.version ? selectedBox : undefined}
        >
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                {t('surcharge.reporting.version')}: {receipt.version}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="end" paddingBottom={3}>
              <Typography>
                {new Date(receipt.createdDate).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Link onClick={() => handleClick(receipt)} button={true}>
                  <Fragment key=".0">
                    {t('surcharge.receipt.viewReport')}
                    <Icon name="arrow_right" />
                  </Fragment>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link
                href={`data:image/pdf;base64,${reportedReceipt?.innhold}`}
                download={`rapport-${organizationNumber}-${year}-${sequence}-${receipt.version}.pdf`}
              >
                <Fragment key=".0">
                  {t('surcharge.receipt.downloadReport')}{' '}
                  <Icon name="download" />
                </Fragment>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export default VersionCard;
