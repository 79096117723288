import { FC, Fragment, useLayoutEffect } from 'react';

import useAuthentication from '../hooks/useAuthentication';
import { api } from '../utils/apiClient';

// Provider used to add access token to requests performed by the api.
export const ApiProvider: FC = ({ children }) => {
  const { userData } = useAuthentication();

  // Update authorization header for the axios instance every time the access token is refreshed
  useLayoutEffect(() => {
    api.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${userData?.access_token}`;
  }, [api, userData?.access_token]);

  return <Fragment>{children}</Fragment>;
};
