import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const queryKeys = {
  user: 'user',
  personer: 'personer',
  organisasjoner: 'organisasjoner',
  finnAktorer: 'finnAktorer',
};
