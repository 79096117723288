import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const queryKeys = {
  availableTerminer: 'surcharge_availableTerminer',
  getReportedData: 'surcharge_getReportedData',
  getGetReceipt: 'surcharge_getGetReceipt',
  attest: 'surcharge_attest',
  grunnlagsdataRevisorattes: 'surcharge_grunnlagsdataRevisorattes',
  metadata: 'surcharge_metadata',
};
