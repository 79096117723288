import { Box, BoxProps, styled } from 'enova-frontend-components';

type ImageBoxProps = BoxProps & {
  bgPosX?: string;
  bgPosY?: string;
  imgSrc: string;
  variant?: 'primary' | 'secondary';
};

const ImageBox = styled(
  // eslint-disable-next-line
  ({ imgSrc, bgPosX, bgPosY, ...rest }: ImageBoxProps) => <Box {...rest} />
)`
  background-image: ${({ imgSrc }) => `url(${imgSrc})`};
  background-position-x: ${({ bgPosX = 'center' }) => bgPosX};
  background-position-y: ${({ bgPosY = 'center' }) => bgPosY};
  background-size: cover;
  background-color: ${({ theme, variant }) =>
    variant === 'primary' ? theme.palette.primary.main : ''};
`;

export default ImageBox;
