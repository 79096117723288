import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Grunnlagsdata } from '../types';
import { getGrunnlagsdataRevisorattest } from '../api';
import { queryKeys } from '../react-query';

export const useGetGrunnlagsdataRevisorattest = (
  organizationNumber: string,
  year: string
): {
  auditorBasis: Grunnlagsdata | undefined;
  isLoading: boolean;
  hasError: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.grunnlagsdataRevisorattes, organizationNumber, year],
    () => getGrunnlagsdataRevisorattest(organizationNumber, year),
    {
      staleTime: 3600,
      cacheTime: 3600,
      onError: () => {
        addSnack(t('surcharge.fetchAvailableTerminer.error'), {
          variant: 'error',
        });
      },
    }
  );

  return {
    auditorBasis: data,
    isLoading,
    hasError: isError,
  };
};
