import { useMutation } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { sign } from '../api';
import { SigningRequest } from '../types';

interface Options {
  onSuccess: (data: unknown) => void;
  onError: (error: unknown) => void;
}

export const useSigning = (): {
  sign: (signingRequest: SigningRequest, options: Options) => void;
  isLoading: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (signingRequest: SigningRequest) => sign(signingRequest),
    {
      onError: () => {
        addSnack(t('surcharge.attest.error'), { variant: 'error' });
      },
      retry: 10,
      retryDelay: 750,
    }
  );

  return {
    sign: mutate,
    isLoading,
  };
};
