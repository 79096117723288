import {
  Box,
  Grid,
  MainContentBox,
  Typography,
} from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useAppData from '../../../../hooks/useAppData';
import useAuthentication from '../../../../hooks/useAuthentication';
import { SelectedAktor } from '../../../../utils/types';
import ImageBox from '../../../../components/imageBox';

import { ViewAction } from './types';
import { LoginView, ReportView, SelectOrganization } from './components';

const getConditionalView = (
  selectedAktor: SelectedAktor | null,
  isAuthenticated: boolean
): ViewAction => {
  if (!isAuthenticated) return ViewAction.LOGIN;
  if (selectedAktor?.access.surcharge) return ViewAction.REPORT;
  return ViewAction.SELECT_ORGANIZATION;
};

export const ArticlePage: FC = () => {
  const { t } = useTranslation();
  const { selectedAktor } = useAppData();
  const { isAuthenticated } = useAuthentication();

  const view = getConditionalView(selectedAktor, isAuthenticated);

  return (
    <MainContentBox padding="xtOnly">
      <Grid marginRight={8} paddingBottom={8} width={'100%'}>
        <ImageBox
          display="flex"
          variant="primary"
          imgSrc="https://www.enova.no/upload_images/0FD516AD3D3B48069067A15A9C2E665A.jpg"
          minHeight={{ xs: '30vh' }}
        />

        <Box paddingTop={4}>
          <Typography variant="h2">{t('surcharge.article.header')}</Typography>
          <Typography variant="body1">
            {t('surcharge.article.ingress')}
          </Typography>
          {view === ViewAction.LOGIN && <LoginView />}
          {view === ViewAction.REPORT && <ReportView />}
          {view === ViewAction.SELECT_ORGANIZATION && <SelectOrganization />}
        </Box>
      </Grid>
    </MainContentBox>
  );
};
