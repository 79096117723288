import { FC } from 'react';
import { Button, Dialog } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAuthentication from './../../hooks/useAuthentication';

const LoginDialog: FC = () => {
  const { userData, signOut, signIn } = useAuthentication();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Dialog
      open={userData?.expired ?? false}
      aria-labelledby="login-dialog-title"
      aria-describedby="login-dialog-description"
    >
      <Dialog.Title id="login-dialog-title">
        {t(`dialog.login.title`)}
      </Dialog.Title>
      <Dialog.Content>{t(`dialog.login.description`)}</Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={() => {
            signOut();
            history.push('/');
          }}
        >
          {t(`logOut`)}
        </Button>
        <Button onClick={() => signIn()} autoFocus>
          {t(`LogIn`)}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default LoginDialog;
