import { useMutation } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { attest } from '../api';

interface Options {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

interface AttestInput {
  organizationNumber: string;
  year: string;
}

export const useAttestation = (): {
  attest: (report: AttestInput, options: Options) => void;
  isLoading: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    ({ organizationNumber, year }: AttestInput) =>
      attest(organizationNumber, year),
    {
      onError: () => {
        addSnack(t('surcharge.attest.error'), { variant: 'error' });
      },
    }
  );

  return {
    attest: mutate,
    isLoading,
  };
};
