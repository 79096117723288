import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { StartPage } from './start';
import { ArticlePage } from './article';

export const ContentPage: FC = () => {
  return (
    <Switch>
      <Route component={ArticlePage} path={`/article/:id`} />
      <Route component={StartPage} exact path={`/`} />
    </Switch>
  );
};
