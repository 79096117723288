import React, { FC, forwardRef } from 'react';
import { Link as RrdLink, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Link as EfcLink, styled } from 'enova-frontend-components';

export type RouterLinkProps = LinkProps & {
  scrollToTop?: boolean;
  hideUnderline?: boolean;
  customComponent?: boolean;
  ref?: React.ForwardedRef<HTMLAnchorElement>;
};

const StyledLink = styled(RrdLink)`
  color: inherit;

  &.hide-underline {
    text-decoration: none;
  }
` as typeof RrdLink;

// eslint-disable-next-line
const Link: FC<any> = forwardRef(({ navigate, ...rest }, ref) => (
  <EfcLink {...rest} ref={ref} />
));

const RouterLink: FC<Omit<RouterLinkProps, 'component'>> = forwardRef<
  HTMLAnchorElement,
  RouterLinkProps
>(
  (
    {
      className,
      onClick,
      scrollToTop,
      customComponent,
      hideUnderline,
      ...rest
    },
    ref
  ) => (
    <StyledLink
      {...rest}
      className={classNames(
        {
          'hide-underline': hideUnderline,
        },
        className
      )}
      onClick={(e) => {
        scrollToTop && !e.ctrlKey && window.scrollTo(0, 0);
        onClick && onClick(e);
      }}
      component={customComponent ? Link : undefined}
      ref={ref}
    />
  )
);

RouterLink.displayName = 'RouterLink';

export default RouterLink;
