import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import {
  Divider,
  Layout,
  Menu,
  Typography,
  Loader,
  styled,
  TextField,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useAppData from '../../../hooks/useAppData';
import { useAktorer } from '../../../hooks/useAktorer';
import { useSelectAktor } from '../../../hooks/useSelectAktor';

const Title = styled.div`
  flex: display;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(3)};
`;

type UserMenuProp = { disablePortal?: boolean };

const MAX_LIST_ITEMS = 25;

const UserMenu: FC<UserMenuProp> = ({ disablePortal }) => {
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const { selectedAktor } = useAppData();
  const { aktorer, isLoading: isAktorerLoading } = useAktorer();
  const { selectAktor, isLoading: isSelectAktorLoading } = useSelectAktor();
  const { t } = useTranslation();

  const filteredAktors = useMemo(() => {
    if (searchPhrase !== '') {
      return aktorer
        .filter((y) =>
          y.name.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase())
        )
        .slice(0, MAX_LIST_ITEMS);
    }
    return aktorer.slice(0, MAX_LIST_ITEMS);
  }, [searchPhrase, aktorer]);

  const hasMoreAktors = aktorer.length > MAX_LIST_ITEMS;

  const isLoading = isAktorerLoading || isSelectAktorLoading;

  const onSelectAktor = useCallback(
    (aktor) => {
      selectAktor(aktor);
    },
    [selectAktor]
  );

  return (
    <Menu>
      <Menu.Button
        aria-label={t(`selectuser.representing`, {
          name: selectedAktor?.name ?? 'Velg aktør',
        })}
        component={Layout.AppBar.Button}
        dropdownIcon
        fullWidth
        className="user-select--button"
        title={selectedAktor?.name ?? 'Velg aktør'}
      >
        {!isLoading && (
          <Typography
            noWrap
            className="f-fill text-start"
            variant="inherit"
            component="span"
          >
            <span>{selectedAktor?.name ?? 'Velg aktør'}</span>
          </Typography>
        )}

        {isLoading && <Loader size="small" color="inherit" />}
      </Menu.Button>
      <Menu.Content
        autoFocusItem={false}
        disablePortal={disablePortal}
        style={{ maxHeight: '60vh', overflow: 'scroll', overflowX: 'hidden' }}
        title={
          <Fragment>
            <Title>
              <Typography variant="h6">{t('selectUserTitle')}</Typography>
              {hasMoreAktors && (
                <TextField
                  value={searchPhrase}
                  placeholder={t('usermenu.filter.textbox.placeholder')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchPhrase(event.target.value)
                  }
                ></TextField>
              )}
            </Title>
            <Divider />
          </Fragment>
        }
      >
        {filteredAktors.map((aktor, index) => (
          <Menu.ListItem
            button
            divider={index < aktorer.length - 1}
            key={aktor.id}
            onClick={() => onSelectAktor(aktor)}
            selected={aktor.id === selectedAktor?.id}
            subheading={aktor.id}
          >
            {aktor.name.toUpperCase()}
            <Typography variant="body2" color="error">
              {aktor.erAktiv ? '' : t('inactive')}
            </Typography>
          </Menu.ListItem>
        ))}
      </Menu.Content>
    </Menu>
  );
};

export default UserMenu;
