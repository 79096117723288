import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { InvoiceDto } from '../types';
import { getGetReceipt } from '../api';
import { queryKeys } from '../react-query';

export const useGetReportedReceipt = (
  organizationNumber: string,
  year: string,
  sequence: number,
  version: number
): {
  invoice: InvoiceDto | undefined;
  isLoading: boolean;
  hasError: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getGetReceipt, organizationNumber, year, sequence, version],
    () => getGetReceipt(organizationNumber, year, sequence, version),
    {
      staleTime: 3600,
      cacheTime: 3600,
      onError: () => {
        addSnack(t('surcharge.getReportedReceipt.error'), { variant: 'error' });
      },
    }
  );

  return {
    invoice: data,
    isLoading,
    hasError: isError,
  };
};

export const usePollForReportedReceipt = (
  organizationNumber: string,
  year: string,
  sequence: number,
  version: number
): {
  invoice: InvoiceDto | undefined;
  isLoading: boolean;
  hasError: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getGetReceipt, organizationNumber, year, sequence, version],
    () => getGetReceipt(organizationNumber, year, sequence, version),
    {
      refetchInterval: (fetchedData) => (fetchedData ? 0 : 1000),
      staleTime: 3600,
      cacheTime: 36000,
      onError: () => {
        addSnack(t('surcharge.getReportedReceipt.error'), { variant: 'error' });
      },
    }
  );

  return {
    invoice: data,
    isLoading,
    hasError: isError,
  };
};
