import { Fragment, ReactElement } from 'react';
import { TFunction } from 'react-i18next';
import _ from 'lodash';
import { TTableHeadCell } from 'enova-frontend-components';

import { getTermInMonths } from '../../utility/utils';
import { SelectedAktor } from '../../../../utils/types';
import { RapportAr, Termin } from '../../types';

import { TableRow } from './types';
import { StyledRouterLink } from './styled';

export const getAuditorNavigationLink = (
  t: TFunction,
  selectedAktor: SelectedAktor,
  organizationNumber: string,
  organizationName: string,
  reportAr: RapportAr
): ReactElement | null => {
  if (reportAr.signert) {
    return null;
  }

  return (
    <StyledRouterLink
      to={{
        pathname: '/surcharge/attestation',
        state: {
          organizationNumber: organizationNumber,
          organizationName: organizationName,
          year: reportAr.ar,
        },
      }}
    >
      {t('surcharge.attestation.attest')}
    </StyledRouterLink>
  );
};

const linkText = (t: TFunction, hasDelivered: boolean) => {
  if (hasDelivered) return t('surcharge.term.edit');
  else return t('surcharge.term.sendReport');
};

const statusText = (
  t: TFunction,
  isOpen: boolean,
  hasDelivered: boolean,
  deadline: Date
) => {
  if (!isOpen) {
    return t('surcharge.termin.notOpen');
  }
  if (isOpen) {
    if (hasDelivered) return t('surcharge.termin.delivered');

    const today = new Date();
    if (today >= deadline && !hasDelivered)
      return t('surcharge.termin.notDelivered');
  }
  return t('surcharge.termin.open');
};

const auditingStatus = (t: TFunction, signed: boolean) => {
  if (signed) {
    return t('surcharge.overview.status.signed');
  }

  return t('surcharge.attestation.notReceived');
};

export const getReportAction = (
  t: TFunction,
  isSigned: boolean,
  term: Termin,
  organizationNumber: string,
  organizationName: string
): ReactElement | null => {
  if (isSigned) return <Fragment></Fragment>;
  if (term.erApen) {
    return (
      <StyledRouterLink
        to={{
          pathname: '/surcharge/report',
          state: {
            organizationNumber: organizationNumber,
            organizationName: organizationName,
            year: term.ar,
            sequence: term.sekvens,
            deadline: term.frist,
            isCorrection: term.harRapportertNettariff,
          },
        }}
      >
        {t(linkText(t, term.harRapportertNettariff))}
      </StyledRouterLink>
    );
  }
  return null;
};

export const mapTermData = (
  t: TFunction,
  reportForYear: RapportAr,
  organizationName: string,
  organizationNumber: string
): TableRow[] => {
  const { terminer: terms } = reportForYear;
  if (terms == null || !terms.some) {
    return [];
  }
  const orderedTerms = _.orderBy(terms, (term) => term.frist);
  return orderedTerms.map((term) => {
    const deadline = new Date(term.frist);
    return {
      id: `${term.ar}-${term.sekvens}`,
      year: term.ar,
      period: getTermInMonths(t, term.sekvens),
      deadline: deadline.toLocaleDateString(),
      term: term.harRapportertNettariff ? (
        <StyledRouterLink
          to={{
            pathname: '/surcharge/receipt-overview',
            state: {
              organizationNumber: organizationNumber,
              organizationName: organizationName,
              year: term.ar,
              sequence: term.sekvens,
              deadline: term.frist,
            },
          }}
        >
          {term.sekvens + '. ' + t('termin') + ' ' + term.ar}
        </StyledRouterLink>
      ) : (
        term.sekvens + '. ' + t('termin') + ' ' + term.ar
      ),
      status: statusText(t, term.erApen, term.harRapportertNettariff, deadline),
      actions: getReportAction(
        t,
        reportForYear.signert,
        term,
        organizationNumber,
        organizationName
      ),
    };
  });
};

export const getTableData = (
  t: TFunction,
  selectedAktor: SelectedAktor,
  reportForYear: RapportAr,
  organizationName: string,
  organizationNumber: string
): TableRow[] => {
  const mappedData = mapTermData(
    t,
    reportForYear,
    organizationName,
    organizationNumber
  );

  mappedData.push({
    id: `${reportForYear.ar}`,
    term: (
      <StyledRouterLink
        to={{
          pathname: `/surcharge/attestation/overview/${organizationNumber}/${reportForYear.ar}`,
        }}
      >
        {t('surcharge.attestation.auditorsCertificate').toUpperCase()}{' '}
        {reportForYear.ar}
      </StyledRouterLink>
    ),
    status: auditingStatus(t, reportForYear.signert),
    year: null,
    period: null,
    deadline: null,
    actions: getAuditorNavigationLink(
      t,
      selectedAktor,
      organizationNumber,
      organizationName,
      reportForYear
    ),
  });
  return mappedData;
};

export const getTableHead = (t: TFunction): TTableHeadCell<TableRow>[] => {
  return [
    {
      id: 'term',
      label: t('surcharge.termin.this'),
    },
    {
      id: 'period',
      label: t('surcharge.termin.period'),
    },
    {
      id: 'deadline',
      label: t('surcharge.termin.deadline'),
    },
    {
      id: 'status',
      label: t('surcharge.termin.status'),
    },
    {
      id: 'actions',
      label: '',
    },
  ];
};
