import React, { FC } from 'react';
import { Box } from 'enova-frontend-components';

import { CONTENT_WIDTH, CONTENT_PADDING } from '../utils';

const MainContentBox: FC = ({ children }) => (
  <Box margin="0 auto" maxWidth={CONTENT_WIDTH} p={CONTENT_PADDING} width={1}>
    {children}
  </Box>
);

export default MainContentBox;
