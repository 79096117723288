import React, { FC } from 'react';
import { Box, Footer } from 'enova-frontend-components';

type FooterColProps = { title: string };

const FooterCol: FC<FooterColProps> = ({ children, title }) => (
  <Box px={{ lg: 2 }} py={3} width={{ lg: 1 / 5 }}>
    <Footer.CategoryHeader className="mb-3" variant="body2">
      <strong>{title.toUpperCase()}</strong>
    </Footer.CategoryHeader>

    {children}
  </Box>
);

export default FooterCol;
