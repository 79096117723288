import { Box, FullScreenLoader, Typography } from 'enova-frontend-components';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useAuthentication from '../../hooks/useAuthentication';

export enum AppStatus {
  UNREGISTERED_USER = 'unregisteredUser',
  LOADING = 'loading',
  REGISTERED = 'registered',
}

const Login: FC = () => {
  const { push } = useHistory();
  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (isAuthenticated) {
      push('/');
    }
  }, [isAuthenticated]);

  return (
    <FullScreenLoader open={true}>
      <Box alignItems="center" display="flex" flexDirection="column">
        <Typography component="p" variant="h4">
          Logging in..
        </Typography>
      </Box>
    </FullScreenLoader>
  );
};

export default Login;
