import { FC } from 'react';
import {
  Box,
  Grid,
  Paper,
  styled,
  useEnovaContext,
  useMediaQuery,
  Link,
  LinkProps,
} from 'enova-frontend-components';
import classNames from 'classnames';

import ImageBox from '../../../components/imageBox';

type LinkBlockProps = Omit<LinkProps, 'color'> & {
  imgSrc: string;
  imgAlign?: 'left' | 'right';
  color?: 'default' | 'primary';
};

const StyledLink = styled(Link)`
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px !important;
  text-decoration: none;
  &::after {
    background: transparent;
    bottom: 0;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    transition: ${({ theme }) =>
      `${theme.transitions.duration.standard}ms  ${theme.transitions.easing.easeInOut}`};
    top: 0;
  }

  &:hover::after {
    background: ${({ theme }) => theme.palette.action.focus};
  }

  &:focus-visible::after {
    background: ${({ theme }) => theme.palette.action.focus};
  }

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
` as typeof Link;

const LinkBlock: FC<LinkBlockProps> = ({
  children,
  className,
  color = 'default',
  imgAlign = 'left',
  imgSrc,
  ...rest
}) => {
  const { theme } = useEnovaContext();

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledLink
      className={classNames('d-block', 'text-inherit', 'fvdbs', className)}
      {...rest}
    >
      <Paper
        className={classNames('o-hidden', {
          'bg-primary': color === 'primary',
        })}
      >
        <Grid
          container
          direction={
            xsDown ? 'column' : imgAlign === 'right' ? 'row-reverse' : 'row'
          }
        >
          <Grid item xs={12} sm={6} lg={4}>
            <ImageBox
              display="flex"
              height={1}
              imgSrc={imgSrc}
              minHeight={{ xs: '50vw', sm: 260 }}
              width={1}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={8}>
            <Box px={{ xs: 4, md: 6 }} py={4} width={1}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </StyledLink>
  );
};

export default LinkBlock;
