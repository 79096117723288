import React, { FC, Fragment, useState, useCallback } from 'react';
import {
  Button,
  Grid,
  Box,
  MainContentBox,
  Paper,
  Typography,
  Divider,
  Form,
  Table,
  TTableHeadCell,
  Checkbox,
  useSnack,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';

import { useReportKwh } from '../../hooks/useReporting';
import { SurchargeHeader } from '../../components/surchargeHeader';
import NavigationHeader from '../../components/navigationHeader';
import {
  RapporterNettariff,
  ReportValues,
  SurchargeStatusType,
} from '../../types';
import { ProblemDetails, instanceOf } from '../../../../utils/types';

interface TableRow {
  id: string;
  headerText: string;
  value: string | number;
}
[];

const ConfirmReport: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { addSnack } = useSnack();

  const [checked, setChecked] = useState(false);

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const values = location.state as ReportValues;
  const {
    invoicedKwh,
    invoicedKwhPrivateHouseholds,
    noOfUniqueIdsInNetwork,
    noOfUniqueIdsInvoiced,
    amountInvoiced,
    organizationNumber,
    organizationName,
    year,
    sequence,
    deadline,
    isCorrection,
    previouslyReported,
  } = values || {};
  const sumSurcharge = (amountInvoiced ?? 0) + (invoicedKwh ?? 0 * 0.01);
  const { sendReport, isLoading } = useReportKwh();
  const sumSurchargeDiff = parseFloat(
    (sumSurcharge - previouslyReported ?? 0).toFixed(2)
  );

  const onSave = useCallback(() => {
    const value: RapporterNettariff = {
      organisasjonsnummer: organizationNumber,
      kilowattimer: invoicedKwhPrivateHouseholds,
      ar: year,
      sekvens: sequence,
      unikeMalepunktIderFakturert: noOfUniqueIdsInvoiced,
      totaltUnikeMalepunktIder: noOfUniqueIdsInNetwork,
      fakturertForMalepunktIDer: amountInvoiced,
    };

    sendReport(value, {
      onSuccess: () => {
        history.push({
          pathname: 'receipt',
          state: {
            organizationNumber: organizationNumber,
            organizationName: organizationName,
            year: year,
            sequence: sequence,
          },
        });
      },
      onError: (err) => {
        if (instanceOf<ProblemDetails>(err)) {
          if (err.status === 409) {
            addSnack(t('surcharge.reporting.saveFailed.conflict'), {
              variant: 'error',
            });
            return;
          }
          addSnack(t('surcharge.reporting.saveFailed.default'), {
            variant: 'error',
          });
        }
      },
    });
  }, [values, sendReport]);

  const headerCells: TTableHeadCell<TableRow>[] = [
    {
      id: 'headerText',
      label: 'None',
    },
    {
      id: 'value',
      label: 'None',
    },
  ];

  const tableValues: TableRow[] = [
    {
      id: 'organizationName',
      headerText: organizationName,
      value: organizationNumber,
    },
    {
      id: 'surcharge.reporting.soldKwh',
      headerText: t('surcharge.reporting.soldKwh'),
      value: invoicedKwhPrivateHouseholds,
    },
    {
      id: 'surcharge.reporting.invoicedMeterIdsInNok',
      headerText: t('surcharge.reporting.invoicedMeterIdsInNok'),
      value: amountInvoiced,
    },
    {
      id: 'surcharge.reporting.term',
      headerText: t('surcharge.reporting.term'),
      value: `${sequence}. ${t('surcharge.reporting.term')} ${year}`,
    },
    {
      id: 'surcharge.reporting.totalSurcharge',
      headerText: t('surcharge.reporting.totalSurcharge'),
      value: `${parseFloat(sumSurcharge.toFixed(2))} NOK`,
    },
  ];

  if (isCorrection) {
    tableValues.push({
      id: 'surcharge.reporting.correctionDifferenceLastReport',
      headerText: t('surcharge.reporting.correctionDifferenceLastReport'),
      value: `${sumSurchargeDiff} NOK`,
    });
  }

  const headerText = isCorrection
    ? t('surcharge.reporting.confirmCorrectionContent')
    : t('surcharge.reporting.confirmReportContent');

  const infoText = isCorrection
    ? t('surcharge.reporting.confirmCorrectionText')
    : t('surcharge.reporting.confirmText');

  tableValues.push({
    id: 'surcharge.reporting.confirmCorrectionContent',
    headerText: t('surcharge.reporting.paymentDueDate'),
    value: new Date(deadline).toLocaleDateString(),
  });

  return (
    <Fragment>
      <MainContentBox padding="xtOnly">
        <NavigationHeader selectedTab={2}></NavigationHeader>
        <Paper>
          <Grid container>
            <Form className="d-flex f-column">
              <SurchargeHeader
                sequence={sequence}
                year={year}
                statusType={
                  isCorrection
                    ? SurchargeStatusType.InProgress
                    : SurchargeStatusType.Draft
                }
                version={null}
              ></SurchargeHeader>
              <Box
                padding={3}
                display="flex"
                alignItems="start"
                flexDirection="column"
              >
                <Typography variant="h1" component="p" gutterBottom>
                  {headerText}
                </Typography>
                <Typography paragraph>{infoText}</Typography>
                <Grid container padding={2}>
                  <Table
                    id="summaryTable"
                    alternating
                    dense
                    disablePagination
                    headCells={headerCells}
                    rows={tableValues}
                    hideHeader
                    shadow="none"
                  />
                  <Grid item xs={12} paddingBottom={5}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography paragraph>
                      {t('surcharge.reporting.surchargeCorrectionInformation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} paddingBottom={5}>
                    <Checkbox
                      label={
                        <Fragment>
                          <Typography variant="body2">
                            {t('surcharge.reporting.iConfirm')}
                          </Typography>
                        </Fragment>
                      }
                      value={checked}
                      onChange={handleCheckedChange}
                      width="fullWidth"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm="auto">
                    <RouterLink
                      to={{
                        pathname: '/surcharge/report',
                        state: {
                          ...values,
                        },
                      }}
                    >
                      <Button variant="secondary" leftIcon="arrow_left">
                        {t('back')}
                      </Button>
                    </RouterLink>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <RouterLink
                      to={{
                        pathname: '/surcharge/overview',
                      }}
                    >
                      <Button variant="secondary" leftIcon="close">
                        {t('cancel')}
                      </Button>
                    </RouterLink>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <Button
                      variant="primary"
                      rightIcon="arrow_right"
                      disabled={isLoading || !checked}
                      onClick={onSave}
                    >
                      {t('surcharge.reporting.confirmAndSend')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Grid>
        </Paper>
      </MainContentBox>
    </Fragment>
  );
};

export default ConfirmReport;
