import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useNettariffMetadata } from '../../../hooks/useNettariffMetadata';
import { ReportValues } from '../../../types';

import Correction from './correction';
import InitialReport from './initialReport';

const Report: FC = () => {
  const location = useLocation();
  const selectedValues = location.state as ReportValues;
  const { metadata } = useNettariffMetadata();

  if (selectedValues.isCorrection)
    return <Correction metadata={metadata} selectedValues={selectedValues} />;
  return <InitialReport metadata={metadata} selectedValues={selectedValues} />;
};

export default Report;
