import { FC, Fragment } from 'react';
import {
  Button,
  Grid,
  Box,
  Loader,
  Paper,
  Typography,
  Link,
  Form,
  Table,
  TTableHeadCell,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { SurchargeHeader } from '../../components/surchargeHeader';
import { SurchargeStatusType } from '../../types';
import { useGetReportedReceipt } from '../../hooks/useGetReportedReceipt';

import { ReceiptValues } from './types';

interface TableRow {
  id: string;
  headerText: string | undefined;
  value: string | number;
}
[];

type Props = {
  receiptValues: ReceiptValues;
};

const SurchargeOverviewPanel: FC<Props> = ({ receiptValues }) => {
  const { t } = useTranslation();

  const { organizationNumber, year, sequence, version } = receiptValues;
  const { invoice: receipt } = useGetReportedReceipt(
    organizationNumber,
    year,
    sequence,
    version
  );

  const headerCells: TTableHeadCell<TableRow>[] = [
    {
      id: 'headerText',
      label: 'None',
    },
    {
      id: 'value',
      label: 'None',
      align: 'right',
    },
  ];

  const tableValues: TableRow[] = [
    {
      id: 'organizationName',
      headerText: receiptValues.organizationName,
      value: receiptValues.organizationNumber,
    },
    {
      id: 'soldKwh',
      headerText: t('surcharge.reporting.soldKwh'),
      value: receiptValues.invoicedKwhPrivateHouseholds,
    },
    {
      id: 'invoicedMeterIdsInNok',
      headerText: t('surcharge.reporting.invoicedMeterIdsInNok'),
      value: receiptValues.amountInvoiced,
    },
    {
      id: 'term',
      headerText: t('surcharge.reporting.term'),
      value: `${receiptValues.sequence}. ${t('surcharge.reporting.term')} ${
        receiptValues.year
      }`,
    },
    {
      id: 'totalSurcharge',
      headerText: t('surcharge.reporting.totalSurcharge'),
      value: `${receiptValues.totalSurcharge} NOK`,
    },
    {
      id: 'deadline',
      headerText: t('surcharge.termin.deadline'),
      value: new Date(receiptValues.deadline).toLocaleDateString(),
    },
  ];

  return receiptValues.version == 0 ? (
    <Paper>
      <Grid
        container
        alignItems="center"
        display="flex"
        justifyContent="center"
        p={4}
      >
        <Loader color="inherit" size="small" />
      </Grid>
    </Paper>
  ) : (
    <Fragment>
      <Grid container spacing={2} paddingLeft={2}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {t('surcharge.reporting.version').toUpperCase() +
              ' ' +
              receiptValues.version}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" component="p" gutterBottom>
            {t('surcharge.reporting.submittedSurchargeHeader')}
          </Typography>
        </Grid>
        {receiptValues.isLastVersion ? (
          <Grid item>
            <RouterLink
              to={{
                pathname: '/surcharge/report',
                state: {
                  organizationNumber: receiptValues.organizationNumber,
                  organizationName: receiptValues.organizationName,
                  year: receiptValues.year,
                  sequence: receiptValues.sequence,
                  deadline: receiptValues.deadline,
                  isCorrection: true,
                },
              }}
            >
              <Button variant="secondary" leftIcon="reset">
                {t('surcharge.reporting.correct')}
              </Button>
            </RouterLink>
          </Grid>
        ) : null}
        <Grid item paddingBottom={4}>
          <Link
            href={`data:image/pdf;base64,${receipt?.innhold}`}
            download={`rapport-${organizationNumber}-${year}-${sequence}-${version}.pdf`}
          >
            <Button variant="secondary" leftIcon="download">
              {t('surcharge.reporting.download')}
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Form className="d-flex f-column">
              <SurchargeHeader
                sequence={receiptValues.sequence}
                year={receiptValues.year}
                statusType={SurchargeStatusType.Sent}
                version={receiptValues.version}
              ></SurchargeHeader>
              <Box
                padding={3}
                display="flex"
                alignItems="start"
                flexDirection="column"
              >
                <Typography variant="h3" component="p" gutterBottom>
                  {t('surcharge.reporting.submittedSurchargeHeader')}
                </Typography>
                <Typography paragraph>
                  {t('surcharge.receipt.infoText')}
                </Typography>
                <Grid container>
                  <Table
                    alternating
                    dense
                    disablePagination
                    headCells={headerCells}
                    rows={tableValues}
                    hideHeader
                    shadow="none"
                  />
                </Grid>
              </Box>
            </Form>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default SurchargeOverviewPanel;
