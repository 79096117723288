import React from 'react';
import { TFunction } from 'react-i18next';

function getTermInMonths(
  t: TFunction<'translation'>,
  sequence: number
): string {
  switch (sequence) {
    case 1:
      return t('months.january') + '/' + t('months.february');
    case 2:
      return t('months.march') + '/' + t('months.april');
    case 3:
      return t('months.may') + '/' + t('months.june');
    case 4:
      return t('months.july') + '/' + t('months.august');
    case 5:
      return t('months.september') + '/' + t('months.october');
    case 6:
      return t('months.november') + '/' + t('months.december');
    default:
      return '';
  }
}

const Remove0OnFocusEvent = (
  event: React.FocusEvent<HTMLInputElement>
): void => {
  if (event.target.value === '0') {
    event.target.value = '';
  }
};

export { getTermInMonths, Remove0OnFocusEvent };
