export enum AppStatus {
  UNREGISTERED_USER = 'unregisteredUser',
  LOADING = 'loading',
  REGISTERED = 'registered',
}

export enum ViewAction {
  LOGIN,
  SELECT_ORGANIZATION,
  REPORT,
}
