import { FC } from 'react';
import { Grid, Box, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

const activeBorder = '2px solid #012263';
const inactiveBorder = '2px solid #FFFFFF';
const NavigationHeader: FC<{ selectedTab: number }> = ({ selectedTab }) => {
  const { t } = useTranslation();
  return (
    <Grid container paddingBottom={2}>
      <Grid item>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            border: selectedTab == 1 ? activeBorder : inactiveBorder,
            borderRadius: '5px',
          }}
        >
          <Typography paddingX={2} paddingY={1}>
            1. {t('surcharge.breadCrumb.step1')}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            border: selectedTab == 2 ? activeBorder : inactiveBorder,
            borderRadius: '5px',
          }}
        >
          <Typography paddingX={2} paddingY={1}>
            2. {t('surcharge.breadCrumb.step2')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NavigationHeader;
