import React, { FC } from 'react';
import {
  Box,
  Footer as FooterBase,
  LargeIcon,
  Link,
  Logo,
  styled,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FacebookLogo } from '../../../assets/icons/icon-facebook.svg';
import { ReactComponent as LinkedInLogo } from '../../../assets/icons/icon-linkedin.svg';
import { ReactComponent as TwitterLogo } from '../../../assets/icons/icon-twitter.svg';
import { ReactComponent as YouTubeLogo } from '../../../assets/icons/icon-youtube.svg';
import { CONTENT_PADDING, CONTENT_WIDTH } from '../utils';

import FooterCol from './footerCol';
import FooterLink from './footerLink';
import SoMeLink from './soMeLink';

const LogoLink = styled(Link)`
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <FooterBase>
      <Box
        margin="0 auto"
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        maxWidth={CONTENT_WIDTH}
        p={CONTENT_PADDING}
        mt={8}
      >
        <Box
          width={{ xs: 1, lg: 1 / 5 }}
          display="flex"
          alignItems="top"
          marginBottom={2}
        >
          <LogoLink
            aria-label={t('home')}
            href="https://enova.no/"
            rel="noopener noreferrer"
            target="_blank"
            transparentBackground={true}
          >
            <Logo />
          </LogoLink>
        </Box>

        <FooterCol title={t('footer.contact')}>
          <FooterLink
            className="d-inline-flex ai-center"
            href="https://www.enova.no/om-enova/kontakt-oss/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LargeIcon className="m-0 mr-2" name="house" />
            {t('footer.contact')}
          </FooterLink>
        </FooterCol>

        <FooterCol title={t('footer.shortcuts')}>
          <FooterLink
            href="https://www.enova.no/om-enova/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.shortcuts.about')}
          </FooterLink>

          <FooterLink
            href="https://presse.enova.no/?_ga=2.187825768.1197838229.1630914813-1901081484.1622444876"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.shortcuts.media')}
          </FooterLink>

          <FooterLink
            href="https://www.enova.no/om-enova/om-organisasjonen/ledige-stillinger/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.shortcuts.positions')}
          </FooterLink>

          <FooterLink
            href="https://www.enova.no/om-enova/om-organisasjonen/postjournal/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.shortcuts.postJournal')}
          </FooterLink>
        </FooterCol>

        <FooterCol title={t('footer.help')}>
          <FooterLink
            href="https://www.enova.no/om-enova/cookies-og-kildevern/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('footer.help.privacy')}
          </FooterLink>

          <FooterLink href="mailto:nettredaktor@enova.no">
            {t('footer.help.webEditor')}
          </FooterLink>
        </FooterCol>

        <FooterCol title={t('footer.soMe')}>
          <SoMeLink
            href="https://www.linkedin.com/company/enova-sf"
            logo={LinkedInLogo}
            rel="noopener noreferrer"
            target="_blank"
          >
            LinkedIn
          </SoMeLink>

          <SoMeLink
            href="https://twitter.com/enovasf"
            logo={TwitterLogo}
            rel="noopener noreferrer"
            target="_blank"
          >
            Twitter
          </SoMeLink>

          <SoMeLink
            href="https://www.facebook.com/enova.no/"
            logo={FacebookLogo}
            rel="noopener noreferrer"
            target="_blank"
          >
            Facebook
          </SoMeLink>

          <SoMeLink
            href="https://www.youtube.com/user/EnovaNO"
            logo={YouTubeLogo}
            rel="noopener noreferrer"
            target="_blank"
          >
            YouTube
          </SoMeLink>
        </FooterCol>
      </Box>
    </FooterBase>
  );
};

export default Footer;
