import { useMutation } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { Aktor, AktorType, RoleType, SelectedAktor } from '../utils/types';
import { loadAktor } from '../api';

import useAppData from './useAppData';

function ToRoleTypeArray(roller: string[]): RoleType[] {
  if (roller === undefined) return [];
  const roles = roller.reduce<RoleType[]>((results, item) => {
    if (Object.values(RoleType).indexOf(item as RoleType) >= 0) {
      return [...results, item as RoleType];
    }
    return results;
  }, []);
  return roles;
}

export const useSelectAktor = (): {
  selectAktor: (aktor: Aktor) => void;
  isLoading: boolean;
} => {
  const { setSelectedAktor } = useAppData();
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (aktor: Aktor) => loadAktor(aktor.id, aktor.aktorType),
    {
      onSuccess: (data, aktor) => {
        const a: SelectedAktor = {
          id: data.identifikator,
          aktorType:
            data.aktortype === 'person'
              ? AktorType.PERSON
              : AktorType.ORGANIZATION,
          name: aktor.name,
          access: {
            surcharge: data.tilganger.some((y) => y === 'Surcharge'),
          },
          roles: ToRoleTypeArray(data.roller),
        };

        setSelectedAktor(a);
      },
      onError: () => {
        setSelectedAktor(null);
        addSnack(t('app.organisasjoner.error'), {
          variant: 'error',
        });
      },
    }
  );

  return {
    selectAktor: mutate,
    isLoading,
  };
};
