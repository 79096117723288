import React, { FC } from 'react';
import {
  Footer,
  LinkProps,
  styled,
  Typography,
} from 'enova-frontend-components';

const FooterLinkWrapper = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const FooterLink: FC<LinkProps> = ({ children, ...rest }) => (
  <FooterLinkWrapper className="mb-1" variant="body2">
    <Footer.Link variant="inherit" {...rest}>
      {children}
    </Footer.Link>
  </FooterLinkWrapper>
);

export default FooterLink;
