import React, { FC, Fragment } from 'react';
import { Box, MainContentBox, Typography } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LogoutScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet title={t('logoutScreen.pageTitle')} />

      <MainContentBox>
        <Box display="flex" flexDirection="column">
          <Typography variant="h1" gutterBottom>
            {t('logoutScreen.title')}
          </Typography>
        </Box>
      </MainContentBox>
    </Fragment>
  );
};

export default LogoutScreen;
