import { FC, useMemo } from 'react';
import {
  Box,
  MainContentBox,
  Table,
  Paper,
  Loader,
  Typography,
  Button,
  Grid,
} from 'enova-frontend-components';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetGrunnlagsdataRevisorattest } from '../../hooks/useGetGrunnlagsdataRevisorattest';
import { useAktorer } from '../../../../hooks/useAktorer';

import { getTableData, getTableHead } from './utils';

const OverviewPage: FC = () => {
  const { year, organization } = useParams<{
    year: string;
    organization: string;
  }>();

  const { t } = useTranslation();
  const history = useHistory();
  const { aktorer } = useAktorer();
  const { auditorBasis, isLoading } = useGetGrunnlagsdataRevisorattest(
    organization,
    year
  );

  const selected = useMemo(() => aktorer.find((y) => y.id === organization), [
    organization,
    aktorer,
  ]);

  const onNavigateBack = () => history.push({ pathname: '../../../overview' });

  const heads = useMemo(() => getTableHead(t), [t]);
  const rows = useMemo(() => {
    if (!isLoading && auditorBasis != undefined) {
      return getTableData(t, auditorBasis);
    }
    return [];
  }, [isLoading, auditorBasis]);

  return (
    <MainContentBox>
      <Box p={4}>
        <Typography variant="h1">
          {t('surcharge.attestation.receipt.header')}
        </Typography>
      </Box>
      <Paper shadow="small" style={{ width: '100%' }}>
        <Grid container p={4}>
          <Grid item xs={12}>
            <Typography variant="h4" pb={4}>
              {t('surcharge.attestation.receipt.subheader')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">
              {t('surcharge.attestation.receipt.name')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">{selected?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">
              {t('surcharge.attestation.receipt.organizationNumber')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">{selected?.id}</Typography>
          </Grid>
        </Grid>
        <Box p={4}>
          {isLoading ? (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              p={4}
            >
              <Loader color="inherit" size="small" />
            </Box>
          ) : (
            <Table
              alternating
              dense
              disablePagination
              headCells={heads}
              rows={rows}
              shadow="none"
            />
          )}
        </Box>
        <Box p={4}>
          <Typography variant="body2">
            {t('surcharge.attestation.receipt.body')}
          </Typography>
        </Box>
      </Paper>
      <Box p={4}>
        <Button
          variant="primary"
          leftIcon="arrow_left"
          onClick={onNavigateBack}
        >
          {t('backToOverview')}
        </Button>
      </Box>
    </MainContentBox>
  );
};

export { OverviewPage };
