import { FC, useState } from 'react';
import {
  Grid,
  Button,
  Divider,
  MainContentBox,
} from 'enova-frontend-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VersionSelect from './versionSelect';
import SurchargeOverviewPanel from './mainPanel';
import { ReceiptValues } from './types';

const ReceiptIndex: FC = () => {
  const { t } = useTranslation();
  const [
    selectedVersion,
    setSelectedReceipt,
  ] = useState<ReceiptValues | null>();

  return (
    <MainContentBox>
      <Grid container spacing={2}>
        <Grid item xs={12} paddingBottom={5}>
          <Link
            to={{
              pathname: '/surcharge/overview',
            }}
          >
            <Button variant="secondary" leftIcon="chevron_left">
              {t('surcharge.receipt.backToOverview')}
            </Button>
          </Link>
        </Grid>
        <Grid item md={3} xl={2}>
          <VersionSelect
            version={selectedVersion?.version || null}
            setSelectedVersion={setSelectedReceipt}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        {selectedVersion && (
          <Grid item md xl>
            <SurchargeOverviewPanel
              receiptValues={selectedVersion}
            ></SurchargeOverviewPanel>
          </Grid>
        )}
      </Grid>
    </MainContentBox>
  );
};

export default ReceiptIndex;
