import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '../utils/react-query';
import { getAktorer } from '../api';
import { Aktor, AktorType } from '../utils/types';

interface useAktorerResponse {
  aktorer: Aktor[];
  isLoading: boolean;
  hasError: boolean;
}

export const useAktorer = (): useAktorerResponse => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.finnAktorer],
    () => getAktorer(),
    {
      staleTime: 3600,
      cacheTime: 3600,
      onError: () => {
        addSnack(t('app.organisasjoner.error'), {
          variant: 'error',
        });
      },
    }
  );

  const aktorer =
    data?.map((o) => ({
      id: o.identifikator,
      name: o.navn,
      aktorType:
        o.aktortype === 'person' ? AktorType.PERSON : AktorType.ORGANIZATION,
      erAktiv: o.erAktiv,
    })) ?? [];

  return {
    aktorer,
    isLoading,
    hasError: isError,
  };
};
