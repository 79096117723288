import { Box, Button, Typography } from 'enova-frontend-components';
import { FC } from 'react';

import {
  CONTENT_PADDING,
  CONTENT_WIDTH,
} from '../../../../components/layout/utils';
import ImageBox from '../../../../components/imageBox';
import LinkBlock from '../../components/linkBlock';

import { MainRubricProps, RubricProps } from './types';
import { StyledRouterLink } from './styled';

export const MainRubric: FC<MainRubricProps> = ({
  title,
  ingress,
  link,
  imageUrl,
}) => {
  return (
    <Box
      display="flex"
      minHeight={{ md: 500 }}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
    >
      <Box
        width={{ xs: 1, sm: 1 / 2 }}
        className="bg-primary"
        display="flex"
        alignItems="center"
      >
        <Box
          display="flex"
          maxWidth={1}
          ml="auto"
          padding={CONTENT_PADDING}
          width={{
            sm: CONTENT_WIDTH.xs / 2,
            lg: CONTENT_WIDTH.lg / 2,
            xl: CONTENT_WIDTH.xl / 2,
          }}
        >
          <Box flex={1} width={1}>
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>

            <Typography paragraph>{ingress}</Typography>

            <StyledRouterLink to={{ pathname: link }}>
              <Button variant="secondary" navigation>
                Les mer
              </Button>
            </StyledRouterLink>
          </Box>
        </Box>
      </Box>

      <ImageBox
        display="flex"
        variant="primary"
        imgSrc={imageUrl}
        minHeight={{ xs: '60vw', sm: 1 }}
        width={{ xs: 1, sm: 1 / 2 }}
      />
    </Box>
  );
};

export const Rubric: FC<RubricProps> = ({ title, ingress, link, imageUrl }) => {
  if (imageUrl === 'left')
    return (
      <LinkBlock
        imgSrc={imageUrl}
        href={link}
        rel="noopener noreferrer"
        targetBlank
      >
        <Typography variant="h4" component="p" gutterBottom>
          {title}
        </Typography>

        <Typography>{ingress}</Typography>
      </LinkBlock>
    );

  return (
    <LinkBlock
      color="primary"
      imgSrc={imageUrl}
      href={link}
      imgAlign="right"
      rel="noopener noreferrer"
      targetBlank
    >
      <Typography variant="h4" component="p" gutterBottom>
        {title}
      </Typography>

      <Typography paragraph>{ingress}</Typography>
    </LinkBlock>
  );
};
