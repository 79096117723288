import { AuthenticationProviderProps } from './hooks/useAuthentication/types';

export const authenticationConfig: Partial<AuthenticationProviderProps> = {
  authority: window.config.authority,
  clientId: window.config.clientId,
  autoSignIn: false,
  responseType: 'code',
  scope: 'openid profile ',
  loadUserInfo: false,
  redirectUri: window.config.redirectUrl,
  postLogoutRedirectUri: window.config.postLogoutRedirectUrl,
};

export const apiConfig: { apiUrl: string } = {
  apiUrl: window.config.apiUrl ?? '',
};

export const locizeConfig: {
  projectId: string;
  apikey: string;
  defaultLanguage: string;
} = {
  projectId: window.config.language.projectId ?? '',
  apikey: window.config.language.apikey ?? '',
  defaultLanguage: window.config.language.default ?? '',
};
