import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

import { locizeConfig } from './config';

const locizeOptions = {
  projectId: locizeConfig.projectId,
  apiKey: locizeConfig.apikey,
  referenceLng: 'dev',
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: ['nn-NO', 'nb-NO', 'en', 'dev'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    fallbackLng: locizeConfig.defaultLanguage,
    backend: locizeOptions,
    saveMissing: true,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
