import { useContext } from 'react';

import AuthenticationContext from './context';
import { AuthenticationContextValues } from './types';

const useAuthentication = (): AuthenticationContextValues => {
  const context = useContext<AuthenticationContextValues | undefined>(
    AuthenticationContext
  );

  if (!context) {
    throw new Error(
      // eslint-disable-next-line max-len
      'AuthenticationProvider context is undefined, please verify you are calling useAuthentication() as child of a <AuthenticationProvider> component.'
    );
  }

  return context;
};
export default useAuthentication;
