import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSnack } from 'enova-frontend-components';

import { MetadataDto } from '../types';
import { queryKeys } from '../react-query';
import { getNettariffMetadata } from '../api';

export const useNettariffMetadata = (): {
  metadata: MetadataDto | undefined;
  isLoading: boolean;
  hasError: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.metadata],
    () => getNettariffMetadata(),
    {
      staleTime: 24 * 3600 * 1000,
      cacheTime: Infinity,
      onError: () => {
        addSnack(t('surcharge.fetchAvailableTerminer.error'), {
          variant: 'error',
        });
      },
    }
  );

  return {
    metadata: data,
    isLoading,
    hasError: isError,
  };
};
