import { useMutation } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { completeSigning, cancelSigning, errorSigning } from '../api';

interface Options {
  onSuccess: (data: unknown) => void;
  onError: (error: unknown) => void;
}

interface SigningHandleRequest {
  id: string;
  token: string;
  type: 'Complete' | 'Cancel' | 'Error';
}

export const useSigningHandler = (): {
  signingHandler: (request: SigningHandleRequest, options: Options) => void;
  isLoading: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (request: SigningHandleRequest) => {
      if (request.type === 'Complete') {
        return completeSigning(request);
      }
      if (request.type === 'Cancel') {
        return cancelSigning(request);
      }
      if (request.type === 'Error') {
        return errorSigning(request);
      }
      return Promise.resolve();
    },
    {
      onError: () => {
        addSnack(t('surcharge.attest.error'), { variant: 'error' });
      },
    }
  );

  return {
    signingHandler: mutate,
    isLoading,
  };
};
