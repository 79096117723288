import { FC, Fragment, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Button,
  Grid,
  Box,
  MainContentBox,
  Paper,
  Typography,
  Icon,
  Dialog,
  DialogContent,
  Link as LinkButton,
  Loader,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, Link } from 'react-router-dom';

import { useGetReportedData } from '../../hooks/useGetReportedData';
import { usePollForReportedReceipt } from '../../hooks/useGetReportedReceipt';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Values = {
  organizationNumber: string;
  organizationName: string | undefined;
  sequence: number;
  year: string;
};

type ReceiptPanelProps = {
  organizationNumber: string;
  sequence: number;
  year: string;
  version: number;
};

type ReceiptDocumentProps = {
  content: string;
};

const ReceiptDocument: FC<ReceiptDocumentProps> = ({ content }) => {
  return (
    <Document file={`data:application/pdf;base64,${content}`}>
      <Page pageNumber={1} scale={1.3} />
    </Document>
  );
};

const ReceiptPreview = ({
  content,
  isOpen,
  onClose,
}: {
  content: string;
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Dialog
    size="md"
    open={isOpen}
    aria-labelledby="dialog-title"
    onClose={onClose}
  >
    <DialogContent>
      <Box display="flex" justifyContent="center">
        <ReceiptDocument content={content} />
      </Box>
    </DialogContent>
  </Dialog>
);

const ReceiptPanel: FC<ReceiptPanelProps> = ({
  organizationNumber,
  sequence,
  year,
  version,
}) => {
  const { t } = useTranslation();

  const { invoice: receipt } = usePollForReportedReceipt(
    organizationNumber,
    year,
    sequence,
    version
  );
  const [isReceiptPreviewVisible, setIsReceiptPreviewVisible] = useState(false);

  if (receipt)
    return (
      <Fragment>
        <Typography variant="h5" align="center">
          {t('surcharge.reporting.invoiceReady')}
        </Typography>

        <Box display="flex" justifyContent="center" mt={8} mb={8}>
          <LinkButton onClick={() => setIsReceiptPreviewVisible(true)}>
            {t('surcharge.reporting.invoicePreview')}
          </LinkButton>

          <Box width={40}></Box>

          <LinkButton
            href={`data:image/pdf;base64,${receipt?.innhold}`}
            download={`rapport-${organizationNumber}-${year}-${sequence}-${version}.pdf`}
          >
            {t('surcharge.reporting.invoiceDownload')}
          </LinkButton>
        </Box>

        <ReceiptPreview
          isOpen={isReceiptPreviewVisible}
          content={receipt.innhold}
          onClose={() => setIsReceiptPreviewVisible(false)}
        />
      </Fragment>
    );

  return (
    <Fragment>
      <Typography variant="h5">
        {t('surcharge.reporting.invoicePreparing')}
      </Typography>
      <Box alignItems="center" display="flex" justifyContent="center" p={4}>
        <Loader color="inherit" size="large" />
      </Box>
    </Fragment>
  );
};

const ReceiptTab: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    organizationName,
    organizationNumber,
    year,
    sequence,
  } = location.state as Values;

  const { reports, isLoading } = useGetReportedData(
    organizationNumber,
    year,
    sequence
  );

  if (isLoading) return <Fragment>{t('surcharge.reporting.loading')}</Fragment>;

  if (!reports) return <Redirect to="/" />;

  if (location.state === undefined) return <Redirect to="/" />;

  const version = Math.max(...reports.map((o) => o.versjon));
  return (
    <Fragment>
      <MainContentBox padding="xtOnly">
        <Paper>
          <Box padding={10}>
            <Grid
              container
              spacing={5}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Icon fontSize="large" name="thumb_up" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {t('surcharge.reporting.reportSent')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('surcharge.reporting.reportRegistered')} {organizationName}
                  . {t('surcharge.reporting.reportRegisteredText')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReceiptPanel
                  organizationNumber={organizationNumber}
                  year={year}
                  sequence={sequence}
                  version={version}
                />
              </Grid>

              <Grid item xs={12}>
                <Link
                  to={{
                    pathname: '/surcharge/overview',
                  }}
                >
                  <Button rightIcon="arrow_right">
                    {t('surcharge.reporting.goToReportOverview')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </MainContentBox>
    </Fragment>
  );
};

export default ReceiptTab;
