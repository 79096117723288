import { FC } from 'react';
import { Grid, Box, Typography, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.greys.grey4};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.greys.grey4)};
  border-radius: 5px 5px 0 0;
  width: 100%;
`;

const AuditingHeader: FC<{ year: string; attested: boolean }> = ({
  year,
  attested,
}) => {
  const { t } = useTranslation();

  const getStatusText = () => {
    if (attested) {
      return t('surcharge.attestation.sent');
    }
    return t('surcharge.attestation.notSent');
  };

  return (
    <StyledBox>
      <Grid container spacing={2} padding={1} paddingLeft={2}>
        <Grid item xs={'auto'}>
          <Typography>{t('surcharge.reporting.workingOn')}:</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography bold>
            {t('surcharge.attestation.annualReport')} {year}
          </Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography bold>{t('surcharge.reporting.status')}:</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography>{getStatusText()}</Typography>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default AuditingHeader;
