import { FC, Fragment, useMemo } from 'react';
import {
  Grid,
  Box,
  MainContentBox,
  Table,
  Paper,
  Typography,
  Link,
  Loader,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import useAppData from '../../../../hooks/useAppData';
import { useGetAvailableTerminer } from '../../hooks/useGetAvailableTerminer';
import { RapportAr } from '../../types';

import { getTableData, getTableHead } from './utils';

const Sidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box>
        <Paper>
          <Box padding={4}>
            <Typography variant="h6">
              {t('surcharge.regulations.link.header')}
            </Typography>
            <Link
              href="https://lovdata.no/dokument/SF/forskrift/2001-12-10-1377"
              targetBlank={true}
              rel={'norefferer'}
            >
              {t('surcharge.regulations.link.content')}
            </Link>
          </Box>
        </Paper>
      </Box>
      <Box paddingTop={4}>
        <Paper>
          <Box padding={4}>
            <Typography variant="h6">
              {t('surcharge.regulations.info.header')}
            </Typography>
            <Typography variant="p">
              <Link
                href="../Brukerveiledning.pdf"
                targetBlank={true}
                rel="noopener noreferrer"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={function noRefCheck() {}}
              >
                {t('surcharge.regulations.info.content')}
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};

const LoadingView: FC = () => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" p={4}>
      <Loader color="inherit" size="small" />
    </Box>
  );
};

interface TableViewProps {
  years: RapportAr[] | undefined;
}

const TableView: FC<TableViewProps> = ({ years }) => {
  const { t } = useTranslation();
  const heads = useMemo(() => getTableHead(t), [t]);
  const { selectedAktor } = useAppData();

  return (
    <Fragment>
      {years
        ?.sort((a, b) => {
          return a.ar > b.ar ? -1 : 1;
        })
        .map((rapport) => {
          return (
            <Fragment key={`rapporteringsAr${rapport.ar}`}>
              <Typography variant="h6">
                {t('surcharge.overview.subHeader')} {rapport.ar}
              </Typography>
              <Grid marginBottom={5}>
                <Table
                  id={`rapporteringsAr${rapport.ar}Table`}
                  alternating
                  dense
                  disablePagination
                  headCells={heads}
                  rows={getTableData(
                    t,
                    selectedAktor!,
                    rapport,
                    selectedAktor?.name ?? '',
                    selectedAktor?.id ?? ''
                  )}
                  shadow="none"
                />
              </Grid>
            </Fragment>
          );
        })}
    </Fragment>
  );
};

const OverviewPage: FC = () => {
  const { t } = useTranslation();

  const { selectedAktor } = useAppData();
  const { id: identifikator } = selectedAktor ?? {};
  const currentYear = new Date().getFullYear();

  const { rapporteringsAr, isLoading } = useGetAvailableTerminer(
    identifikator ?? '',
    [currentYear.toString(), (currentYear - 1).toString()]
  );

  return (
    <MainContentBox>
      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item md={9} sm={12} xs={12}>
          <Paper>
            <Box padding={4} paddingTop={5}>
              <Typography variant="h1">
                {t('surcharge.overview.header')}
              </Typography>
              <Typography>{t('surcharge.overview.helperText')}</Typography>
              {isLoading ? (
                <LoadingView />
              ) : (
                <TableView years={rapporteringsAr} />
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12} md={3} xs={12}>
          <Sidebar />
        </Grid>
      </Grid>
    </MainContentBox>
  );
};

export { OverviewPage };
