import { FC, Fragment } from 'react';
import { Grid } from 'enova-frontend-components';

import MainContentBox from '../../../../components/layout/components/mainContentBox';
import useArticles from '../../hooks/useArticles';

import { MainRubric, Rubric } from './components';

export const StartPage: FC = () => {
  const [articles] = useArticles();

  const headerArticle = articles.length > 0 ? articles[0] : null;
  const articleList = articles.length > 1 ? articles.slice(1) : [];

  return (
    <Fragment>
      {headerArticle && (
        <MainRubric
          imageUrl={headerArticle.imageUrl}
          ingress={headerArticle.ingress}
          link={headerArticle.link}
          title={headerArticle.title}
        />
      )}
      <MainContentBox>
        <Grid container spacing={8}>
          {articleList.map((a, index) => {
            return index % 2 === 0 ? (
              <Grid item xs={12} key={a.id}>
                <Rubric
                  imageUrl={a.imageUrl}
                  ingress={a.ingress}
                  link={a.link}
                  title={a.title}
                  imageAlign="left"
                />
              </Grid>
            ) : (
              <Grid item xs={12} key={a.id}>
                <Rubric
                  imageUrl={a.imageUrl}
                  ingress={a.ingress}
                  link={a.link}
                  title={a.title}
                  imageAlign="right"
                />
              </Grid>
            );
          })}
        </Grid>
      </MainContentBox>
    </Fragment>
  );
};
