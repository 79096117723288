import { useMutation } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { reportKwh } from '../api';
import { RapporterNettariff } from '../types';

interface Options {
  onSuccess: () => void;
  onError: (error: unknown) => void;
}

export const useReportKwh = (): {
  sendReport: (report: RapporterNettariff, options: Options) => void;
  isLoading: boolean;
} => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(
    (report: RapporterNettariff) => reportKwh(report),
    {
      onError: () => {
        addSnack(t('surcharge.attest.error'), { variant: 'error' });
      },
    }
  );

  return {
    sendReport: mutate,
    isLoading,
  };
};
