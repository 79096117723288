import React, { FC, forwardRef } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  styled,
  useEnovaContext,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import RouterLink, { RouterLinkProps } from '../../routerLink';
import { AppRoute, AuthorizedAppRoutes } from '../../../utils/navigation';
import useAppData from '../../../hooks/useAppData';
import useAuthentication from '../../../hooks/useAuthentication';
import { AktorType } from '../../../utils/types';

import UserMenu from './userMenu';

const MenuContent = styled.div`
  .user-select--button {
    border: thin solid ${({ theme }) => theme.palette.divider};
    height: auto;
    padding: ${({ theme }) => theme.spacing(3)};
  }
`;

const SurchargeLink: FC<Omit<
  RouterLinkProps,
  'to' | 'component' | 'ref'
>> = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to' | 'component'>>(
  ({ children, ...rest }, ref) => {
    return (
      <RouterLink
        scrollToTop={true}
        ref={ref}
        to={`/${AppRoute.SURCHARGE}`}
        {...rest}
      >
        {children}
      </RouterLink>
    );
  }
);

const MainMenu: FC = () => {
  const { t } = useTranslation();
  const { setMainMenuOpen } = useEnovaContext();
  const { isAuthenticated } = useAuthentication();
  const { selectedAktor } = useAppData();

  const aktortype = selectedAktor?.aktorType;
  const hasSurcharge = selectedAktor?.access?.surcharge ?? false;
  const surchargeMatch = useRouteMatch(AuthorizedAppRoutes[AppRoute.SURCHARGE]);

  return (
    <AppBar.Menu
      ToggleButtonProps={{
        label: t('mainMenu.label'),
      }}
    >
      <MenuContent className="d-flex f-column f-fill">
        <Hidden mdUp>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UserMenu disablePortal />
              </Grid>
              <Grid item xs={12}>
                <Button variant="secondary" fullWidth>
                  {t('logOut')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Hidden>

        <List>
          {isAuthenticated &&
            aktortype === AktorType.ORGANIZATION &&
            hasSurcharge && (
              <ListItem
                component={SurchargeLink}
                button
                onClick={(e) => !e.ctrlKey && setMainMenuOpen(false)}
                selected={!!surchargeMatch}
              >
                {t('mainMenu.item.surcharge', { context: aktortype })}
              </ListItem>
            )}

          {isAuthenticated && <Box bgcolor="divider" height={20} width={1} />}

          <List.Item
            button
            component="a"
            divider
            href="https://soknad.enova.no/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('mainMenu.item.applicationAndReportCenter')}
          </List.Item>

          <List.Item
            button
            component="a"
            divider
            href="https://www.enova.no/kunnskap/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('mainMenu.item.enovaKnowledge')}
          </List.Item>
        </List>

        {/* <Box p={2} mt="auto">
          <Select
            fullWidth
            label={t('language')}
            options={[
              { label: 'Bokmål', value: 'nb-NO' },
              { label: 'Nynorsk', value: 'nn-NO' },
              { label: 'English', value: 'en' },
            ]}
            onChange={({ currentTarget: { value: lng } }) => {
              i18n.changeLanguage(lng);
              setLng(lng);
            }}
            value={i18n.language}
          />
        </Box> */}
      </MenuContent>
    </AppBar.Menu>
  );
};

export default MainMenu;
