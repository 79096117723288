import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Article } from '../../../utils/types';

const useArticles = (): [Article[], boolean, boolean] => {
  const [values, setValues] = useState<Article[]>([]);
  const [isLoading] = useState<boolean>(false);
  const [hasError] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const article: Article = {
      id: '1',
      bgColor: null,
      imageUrl:
        'https://www.enova.no/upload_images/0FD516AD3D3B48069067A15A9C2E665A.jpg',
      ingress: t('frontpage.tariff.ingress'),
      link: '/article/1',
      title: t('frontpage.tariff.title'),
    };

    setValues([article]);
  }, [t]);

  return [values, isLoading, hasError];
};

export default useArticles;
