import { FC, Fragment, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Box,
  MainContentBox,
  Paper,
  Typography,
  Link,
  Divider,
  Form,
  NumberField,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as _ from 'lodash';

import { useGetReportedData } from '../../../hooks/useGetReportedData';
import { SurchargeHeader } from '../../../components/surchargeHeader';
import NavigationHeader from '../../../components/navigationHeader';
import * as utils from '../../../utility/utils';
import { ReportProps, ReportValues, SurchargeStatusType } from '../../../types';

const Correction: FC<ReportProps> = ({ selectedValues, metadata }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<ReportValues>({
    invoicedKwhPrivateHouseholds:
      selectedValues?.invoicedKwhPrivateHouseholds ?? 0,
    noOfUniqueIdsInNetwork: selectedValues?.noOfUniqueIdsInNetwork ?? 0,
    noOfUniqueIdsInvoiced: selectedValues?.noOfUniqueIdsInvoiced ?? 0,
    amountInvoiced: selectedValues?.amountInvoiced ?? 0,
    sumInvoicedPreviously: selectedValues?.sumInvoicedPreviously ?? 0,
    invoicedKwh: selectedValues?.invoicedKwh ?? 0,
    organizationName: selectedValues?.organizationName,
    organizationNumber: selectedValues?.organizationNumber,
    year: selectedValues?.year,
    sequence: selectedValues?.sequence,
    deadline: selectedValues?.deadline,
    isCorrection: selectedValues?.isCorrection ?? false,
    previouslyReported: 0,
  });

  const {
    invoicedKwhPrivateHouseholds,
    noOfUniqueIdsInNetwork,
    noOfUniqueIdsInvoiced,
    amountInvoiced,
    invoicedKwh,
    organizationNumber,
    sequence,
    year,
    previouslyReported,
  } = values;
  const { reports } = useGetReportedData(organizationNumber, year, sequence);
  const lastVersion = _.maxBy(reports, function (o) {
    return o?.versjon;
  });
  const lastReportedValue = lastVersion?.totaltPaslag ?? 0;
  const { paslagForKWh, paslagForMalepunktId } = metadata ?? {};

  useEffect(() => {
    if (paslagForKWh) {
      const invoiceAmount = parseFloat(
        (paslagForKWh * invoicedKwhPrivateHouseholds).toFixed(2)
      );
      setValues((prev) => ({ ...prev, invoicedKwh: invoiceAmount }));
    }
  }, [invoicedKwhPrivateHouseholds]);

  useEffect(() => {
    setValues((prev) => ({ ...prev, previouslyReported: lastReportedValue }));
  }, [lastReportedValue]);

  return (
    <Fragment>
      <MainContentBox padding="xtOnly">
        <NavigationHeader selectedTab={1}></NavigationHeader>
        <Paper>
          <Grid container>
            <Form className="d-flex f-column">
              <SurchargeHeader
                sequence={sequence}
                year={year}
                statusType={SurchargeStatusType.InProgress}
                version={null}
              ></SurchargeHeader>
              <Box
                padding={3}
                display="flex"
                alignItems="start"
                flexDirection="column"
              >
                <Typography variant="h1" component="p" gutterBottom>
                  {t('surcharge.reporting.correctionHeader')}
                </Typography>
                <Typography paragraph>
                  {t('surcharge.reporting.correctionHelperText')}
                </Typography>
                <Typography paragraph>
                  {t('surcharge.reporting.correctionFormHelp')}{' '}
                  <Link
                    href="../Brukerveiledning.pdf"
                    targetBlank={true}
                    rel="noopener noreferrer"
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={function noRefCheck() {}}
                  >
                    {t('surcharge.reporting.correctionFormHelpLink')}
                  </Link>
                </Typography>
                <Grid container>
                  <Grid item xs={12} paddingBottom={3}>
                    <Typography variant="h4" paddingBottom={3}>
                      {t('surcharge.reporting.correctionSubHeader')}
                    </Typography>
                    <Divider variant="fullWidth" />
                    <Typography variant="h4">
                      {t('surcharge.reporting.households')}
                    </Typography>
                    <Divider variant="fullWidth" />
                  </Grid>
                </Grid>
                <Divider variant="middle" />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <NumberField
                      id="invoicedKwhThisTerm"
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      decimalPrecision={0}
                      label={t(
                        'surcharge.reporting.correctionInvoicedKwhThisTerm'
                      )}
                      placeholder={t('surcharge.reporting.enter')}
                      marginBottom
                      onValueChange={({ floatValue }) =>
                        setValues((prev) => ({
                          ...prev,
                          invoicedKwhPrivateHouseholds: floatValue ?? 0,
                        }))
                      }
                      value={invoicedKwhPrivateHouseholds}
                      onFocus={utils.Remove0OnFocusEvent}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      label={t('surcharge.reporting.rateKrPerKwh')}
                      value={paslagForKWh}
                      prefix="kr"
                      disabled
                      marginBottom
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      id="sumKwhInvoicedThisTerm"
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      label={t(
                        'surcharge.reporting.correctionSumKwhInvoicedThisTerm'
                      )}
                      value={invoicedKwh}
                      disabled
                      marginBottom
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                    <Typography variant="h4">
                      {t('surcharge.reporting.correctionSubHeaderOther')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      id="numberOfMeterIds"
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      decimalPrecision={0}
                      HelperBoxProps={{
                        'aria-label': t('helperBox.ariaLabel'),
                        children: t(
                          'surcharge.reporting.correctionNoOfOtherMeterIdsAtEndOfTermHelperBox'
                        ),
                      }}
                      label={t(
                        'surcharge.reporting.correctionNoOfOtherMeterIdsAtEndOfTerm'
                      )}
                      placeholder={t('surcharge.reporting.enter')}
                      marginBottom
                      onValueChange={({ floatValue }) =>
                        setValues((prev) => ({
                          ...prev,
                          noOfUniqueIdsInNetwork: floatValue ?? 0,
                        }))
                      }
                      value={noOfUniqueIdsInNetwork}
                      onFocus={utils.Remove0OnFocusEvent}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      id="uniqueMeterIdsInvoicedThisTerm"
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      HelperBoxProps={{
                        'aria-label': t('helperBox.ariaLabel'),
                        children: t(
                          'surcharge.reporting.correctionUniqueMeterIdsInvoicedThisTermHelperBox'
                        ),
                      }}
                      decimalPrecision={0}
                      label={t(
                        'surcharge.reporting.correctionUniqueMeterIdsInvoicedThisTerm'
                      )}
                      placeholder={t('surcharge.reporting.enter')}
                      marginBottom
                      onValueChange={({ floatValue }) =>
                        setValues((prev) => ({
                          ...prev,
                          noOfUniqueIdsInvoiced: floatValue ?? 0,
                        }))
                      }
                      value={noOfUniqueIdsInvoiced}
                      onFocus={utils.Remove0OnFocusEvent}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      label={t('surcharge.reporting.ratePerMeter')}
                      value={paslagForMalepunktId}
                      disabled
                      marginBottom
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberField
                      id="sumInvoicedMeterIdsThisTerm"
                      inputProps={{ style: { textAlign: 'left' } }}
                      fullWidth
                      HelperBoxProps={{
                        'aria-label': t('helperBox.ariaLabel'),
                        children: t(
                          'surcharge.reporting.correctionSumInvoicedThisTermHelperBox'
                        ),
                      }}
                      label={t(
                        'surcharge.reporting.correctionSumInvoicedThisTerm'
                      )}
                      placeholder={t('surcharge.reporting.enter')}
                      marginBottom
                      onValueChange={({ floatValue }) =>
                        setValues((prev) => ({
                          ...prev,
                          amountInvoiced: floatValue ?? 0,
                        }))
                      }
                      value={amountInvoiced}
                      onFocus={utils.Remove0OnFocusEvent}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} paddingBottom={2}>
                      <Typography variant="h4">
                        {t('surcharge.reporting.correctionTotalSurcharge')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <NumberField
                        inputProps={{ style: { textAlign: 'left' } }}
                        fullWidth
                        disabled
                        label={t(
                          'surcharge.reporting.correctionPreviouslyReported'
                        )}
                        marginBottom
                        value={previouslyReported}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberField
                        id="sumSurchargeInvoicedThisTerm"
                        inputProps={{ style: { textAlign: 'left' } }}
                        fullWidth
                        disabled
                        label={t(
                          'surcharge.reporting.correctionCorrectedReport'
                        )}
                        marginBottom
                        value={amountInvoiced + invoicedKwh}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} padding={2}>
                    <Divider variant="fullWidth" />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm="auto">
                    <RouterLink
                      to={{
                        pathname: '/surcharge/overview',
                        state: {
                          ...values,
                        },
                      }}
                    >
                      <Button variant="secondary" leftIcon="close">
                        {t('cancel')}
                      </Button>
                    </RouterLink>
                  </Grid>
                  <Grid item xs={12} sm="auto">
                    <RouterLink
                      to={{
                        pathname: '/surcharge/confirm-report',
                        state: {
                          ...values,
                        },
                      }}
                    >
                      <Button
                        id="saveAndContinueBtn"
                        variant="primary"
                        rightIcon="arrow_right"
                      >
                        {t('surcharge.reporting.saveAndContinue')}
                      </Button>
                    </RouterLink>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Grid>
        </Paper>
      </MainContentBox>
    </Fragment>
  );
};

export default Correction;
