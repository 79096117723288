import { TTableHeadCell } from 'enova-frontend-components';
import { TFunction } from 'i18next';
import _ from 'lodash';

import { Grunnlagsdata } from '../../types';

import { AuditorRow } from './types';

export const getTableData = (
  t: TFunction,
  auditorBasis: Grunnlagsdata | null
): AuditorRow[] => {
  if (auditorBasis != undefined) {
    const totalCount = {
      id: 'total',
      kwh: _.sumBy(auditorBasis.grunnlag, (x) => x.kilowattimer).toLocaleString(
        'NO-nb'
      ),
      term: t('surcharge.attestation.total'),
      totalSurcharge: _.sumBy(
        auditorBasis.grunnlag,
        (x) => x.totaltPaslag
      ).toLocaleString('NO-nb'),
      uniqueInvoicedMeterIds: '',
      invoicedForKwh: _.sumBy(
        auditorBasis.grunnlag,
        (x) => x.fakturertForKilowattimer
      ).toLocaleString('NO-nb'),
      invoicedForMeterIds: _.sumBy(
        auditorBasis.grunnlag,
        (x) => x.fakturertForMalepunktIder
      ).toLocaleString('NO-nb'),
    };

    const rader = auditorBasis.grunnlag.map((x) => {
      return {
        id: x.ar + ' ' + x.sekvens,
        kwh: x.kilowattimer.toLocaleString('NO-nb'),
        term: x.sekvens.toString() + '-' + x.ar,
        totalSurcharge: x.totaltPaslag.toLocaleString('NO-nb'),
        uniqueInvoicedMeterIds: x.unikeFakturerteMalepunktIder.toLocaleString(
          'NO-nb'
        ),
        invoicedForKwh: x.fakturertForKilowattimer.toLocaleString('NO-nb'),
        invoicedForMeterIds: x.fakturertForMalepunktIder.toLocaleString(
          'NO-nb'
        ),
      };
    });
    const orderedList = _.orderBy(rader, (x) => x.id);

    orderedList.push(totalCount);
    return orderedList;
  }
  return [];
};

export const getTableHead = (t: TFunction): TTableHeadCell<AuditorRow>[] => {
  return [
    {
      id: 'term',
      label: t('surcharge.termin.this'),
      disableSort: true,
    },
    {
      id: 'kwh',
      label: t('surcharge.attestation.invoicedKwhPrivateHouseholds'),
      disableSort: true,
    },
    {
      id: 'invoicedForKwh',
      label: t('surcharge.attestation.invoicedPrivateHouseholdsNok'),
      disableSort: true,
    },
    {
      id: 'uniqueInvoicedMeterIds',
      label: t('surcharge.attestation.noOfUniqueMeterIdsInvoiced'),
      disableSort: true,
    },
    {
      id: 'invoicedForMeterIds',
      label: t('surcharge.attestation.invoicedForMeterIdsNok'),
      disableSort: true,
    },
    {
      id: 'totalSurcharge',
      label: t('surcharge.attestation.totalSurcharge'),
      disableSort: true,
    },
  ];
};
