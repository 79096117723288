import { FC, useEffect } from 'react';
import { Grid, Typography } from 'enova-frontend-components';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { useGetReportedData } from '../../hooks/useGetReportedData';
import { ReportDto } from '../../types';

import { ReceiptValues, SelectedTerm } from './types';
import VersionCard from './versionCard';

type Props = {
  version: number | null;
  setSelectedVersion: React.Dispatch<
    React.SetStateAction<ReceiptValues | null | undefined>
  >;
};

const VersionSelect: FC<Props> = ({
  version,
  setSelectedVersion: setSelectedReceipt,
}) => {
  const location = useLocation();
  const selectedValues = location.state as SelectedTerm;
  const {
    organizationNumber,
    organizationName,
    sequence,
    year,
    deadline,
  } = selectedValues;
  const { reports, isLoading } = useGetReportedData(
    organizationNumber,
    year,
    sequence
  );
  const lastVersion = _.maxBy(reports, (x) => x.versjon);

  const updateSelectedVersion = (receipt: ReportDto) => {
    const updatedState = {
      organizationNumber: organizationNumber,
      version: receipt.versjon,
      invoicedKwhPrivateHouseholds: receipt.kilowattimer,
      amountInvoiced: receipt.fakturertForMalepunktIder,
      uniqueInvoicedMeterIds: receipt.unikeFakturerteMalepunktIder,
      totalUniqueMeterIds: receipt.totaltUnikeMalepunktIder,
      totalSurcharge: receipt.totaltPaslag,
      createdDate: receipt.datoOpprettet,
      organizationName: organizationName,
      year: year,
      sequence: sequence,
      deadline: deadline,
      isLastVersion: lastVersion?.versjon == receipt.versjon,
    };

    setSelectedReceipt(updatedState);
  };

  const isSet = (selectedVersion: number | null) => {
    return selectedVersion !== null && selectedVersion !== 0;
  };

  useEffect(() => {
    if (!isLoading && !isSet(version) && lastVersion != undefined) {
      updateSelectedVersion(lastVersion);
    }
  }, [isLoading, isSet, lastVersion, updateSelectedVersion]);

  const ordered = _.orderBy(reports, (x) => x.versjon, 'desc');
  const cards = ordered.map((el) => {
    return (
      <VersionCard
        key={el.versjon}
        version={version}
        receipt={{
          organizationNumber: organizationNumber,
          version: el.versjon,
          invoicedKwhPrivateHouseholds: el.kilowattimer,
          amountInvoiced: el.fakturertForMalepunktIder,
          uniqueInvoicedMeterIds: el.unikeFakturerteMalepunktIder,
          totalUniqueMeterIds: el.totaltUnikeMalepunktIder,
          totalSurcharge: el.totaltPaslag,
          createdDate: el.datoOpprettet,
          organizationName: organizationName,
          year: year,
          sequence: sequence,
          deadline: deadline,
          isLastVersion: lastVersion?.versjon == el.versjon,
        }}
        setSelectedVersion={setSelectedReceipt}
      ></VersionCard>
    );
  });
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" component="p" gutterBottom>
          Tidligere versjoner
        </Typography>
      </Grid>
      {cards}
    </Grid>
  );
};

export default VersionSelect;
