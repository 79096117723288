import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { getReportedData } from '../api';
import { queryKeys } from '../react-query';
import { ReportDto } from '../types';

interface ReportsResponse {
  reports: ReportDto[] | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export const useGetReportedData = (
  organizationNumber: string,
  year: string,
  sequence: number
): ReportsResponse => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getReportedData, organizationNumber, year, sequence],
    () => getReportedData(organizationNumber, year, sequence),
    {
      staleTime: 3600,
      cacheTime: 3600,
      onError: () => {
        addSnack(t('surcharge.getReportedData.error'), { variant: 'error' });
      },
    }
  );

  return {
    reports: data,
    isLoading,
    hasError: isError,
  };
};
