import { FC } from 'react';
import {
  Box,
  Hidden,
  Layout as LayoutBase,
  MainProps,
  styled,
} from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAuthentication from '../../hooks/useAuthentication';
import useAppData from '../../hooks/useAppData';

import UserMenu from './components/userMenu';
import MainMenu from './components/mainMenu';
import { CONTENT_PADDING, CONTENT_WIDTH } from './utils';
import Footer from './components/footer';

const StyledAppBar = styled(LayoutBase.AppBar)`
  .user-select--button {
    max-width: 32vw;
  }
` as typeof LayoutBase.AppBar;

export type LayoutProps = {
  fullWidth?: boolean;
  MainProps?: MainProps;
};

const Layout: FC<LayoutProps> = ({
  children,
  fullWidth,
  MainProps: mainProps,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isAuthenticated, signIn, signOutRedirect } = useAuthentication();
  const { setSelectedAktor } = useAppData();

  const logOut = () => {
    signOutRedirect();
    setSelectedAktor(null);
    push('/');
  };

  return (
    <LayoutBase>
      <StyledAppBar
        leftContent={<MainMenu />}
        rightContent={
          <Hidden mdDown>
            {isAuthenticated && <UserMenu />}
            {isAuthenticated && (
              <LayoutBase.AppBar.Button onClick={logOut}>
                {t('logOut')}
              </LayoutBase.AppBar.Button>
            )}
            {!isAuthenticated && (
              <LayoutBase.AppBar.Button onClick={signIn}>
                {t('logIn')}
              </LayoutBase.AppBar.Button>
            )}
          </Hidden>
        }
      />

      <LayoutBase.Main {...mainProps}>
        {fullWidth ? (
          children
        ) : (
          <Box margin="0 auto" maxWidth={CONTENT_WIDTH} p={CONTENT_PADDING}>
            {children}
          </Box>
        )}
      </LayoutBase.Main>

      <Footer />
    </LayoutBase>
  );
};

export default Layout;
