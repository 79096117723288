import { useQuery } from 'react-query';
import { useSnack } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { RapportAr } from '../types';
import { getAvailableTerminer } from '../api';
import { queryKeys } from '../react-query';

interface AvailableTerminerResponse {
  rapporteringsAr: RapportAr[] | undefined;
  isLoading: boolean;
  hasError: boolean;
}

export const useGetAvailableTerminer = (
  organizationNumber: string,
  years: string[]
): AvailableTerminerResponse => {
  const { addSnack } = useSnack();
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery(
    [queryKeys.availableTerminer, organizationNumber, years],
    () => getAvailableTerminer(organizationNumber, years),
    {
      staleTime: 3600,
      cacheTime: 3600,
      onError: () => {
        addSnack(t('surcharge.fetchAvailableTerminer.error'), {
          variant: 'error',
        });
      },
    }
  );

  return {
    rapporteringsAr: data,
    isLoading,
    hasError: isError,
  };
};
