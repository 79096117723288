import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  MainContentBox,
  Paper,
  Loader,
  Box,
  Button,
} from 'enova-frontend-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSigningHandler } from '../../hooks/useSigningHandler';

export type SigningValues = {
  year: string;
  organizationName: string;
  organizationNumber: string;
};

enum Status {
  Success,
  Error,
}

function useSearchParams() {
  const { search } = useLocation();

  return [useMemo(() => new URLSearchParams(search), [search])];
}

const SigningCompletePage: FC = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const { signingHandler, isLoading } = useSigningHandler();
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status | null>(null);
  const history = useHistory();
  const token = searchParams.get('status_query_token');

  useEffect(() => {
    if (token !== null) {
      signingHandler(
        { id, token, type: 'Complete' },
        {
          onSuccess: () => {
            setStatus(Status.Success);
          },
          onError: () => {
            setStatus(Status.Error);
          },
        }
      );
    }
  }, [id, token]);

  const onNavigateBack = () => history.push({ pathname: '../overview' });

  return (
    <Fragment>
      <MainContentBox padding="xtOnly">
        <Paper>
          <Box
            padding={5}
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h3" padding={2}>
              {t('surcharge.signing.complete.header')}
            </Typography>
            {isLoading && (
              <Fragment>
                <Loader size="large"></Loader>
                <Typography variant="body" padding={2}>
                  {t('surcharge.signing.complete.progress.body')}
                </Typography>
              </Fragment>
            )}
            {status === Status.Success && (
              <Fragment>
                <Typography variant="body" padding={4}>
                  {t('surcharge.signing.complete.success.body')}
                </Typography>
                <Button
                  variant="primary"
                  leftIcon="arrow_left"
                  onClick={onNavigateBack}
                >
                  {t('surcharge.signing.backToOverview')}
                </Button>
              </Fragment>
            )}
            {status === Status.Error && (
              <Fragment>
                <Typography variant="body" padding={4}>
                  {t('surcharge.signing.complete.error.body')}
                </Typography>
                <Button
                  variant="primary"
                  leftIcon="arrow_left"
                  onClick={onNavigateBack}
                >
                  {t('surcharge.signing.backToOverview')}
                </Button>
              </Fragment>
            )}
          </Box>
        </Paper>
      </MainContentBox>
    </Fragment>
  );
};

export { SigningCompletePage };
