import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import useAppData from '../../../hooks/useAppData';
import { AktorType } from '../../../utils/types';

import Report from './steps/report';
import { OverviewPage } from './overview';
import ConfirmReport from './steps/confirm';
import ReceiptTab from './steps/receipt';
import ReceiptIndex from './receipt';
import {
  AttestationPage,
  SigningPage,
  OverviewPage as AttestOverviewPage,
} from './attestation';
import { SigningCompletePage, SigningCancelPage } from './signing';

const SurchargeScreen: FC = () => {
  const { selectedAktor } = useAppData();

  if (
    selectedAktor?.aktorType === AktorType.PERSON ||
    !selectedAktor?.access.surcharge
  )
    return <Redirect to="/" />;

  return (
    <Switch>
      <Route component={OverviewPage} exact path={`/surcharge/overview`} />
      <Route component={Report} exact path={`/surcharge/report`} />
      <Route
        component={ConfirmReport}
        exact
        path={`/surcharge/confirm-report`}
      />
      <Route component={ReceiptTab} exact path={`/surcharge/receipt`} />
      <Route
        component={ReceiptIndex}
        exact
        path={`/surcharge/receipt-overview`}
      />
      <Route
        component={AttestOverviewPage}
        exact
        path={`/surcharge/attestation/overview/:organization/:year`}
      />
      <Route component={AttestationPage} path={`/surcharge/attestation`} />

      <Route component={SigningPage} exact path={`/surcharge/signing`} />
      <Route
        component={SigningCompletePage}
        path={`/surcharge/signing/complete/:id`}
      />
      <Route
        component={SigningCancelPage}
        path={`/surcharge/signing/cancel/:id`}
      />
      <Route component={OverviewPage} path={`/surcharge`} />
    </Switch>
  );
};

export default SurchargeScreen;
