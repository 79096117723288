export function instanceOf<T>(object: unknown): object is T {
  return true;
}

export enum AktorType {
  PERSON = 'person',
  ORGANIZATION = 'organisasjon',
}

export enum UserType {
  PERSON = 'person',
  ORGANIZATION = 'organisasjon',
}

export type User = {
  name: string;
  id: string;
  type: UserType;
  showSurcharge: boolean;
  roles: RoleType[];
};

export interface UserDetails {
  socialSecurityNumber?: string;
  email: string;
  phone: string;
  consent: boolean;
  bransje?: string[];
  omrade?: string[];
  organization?: { organizationNumber?: string; name?: string };
}

export interface Aktor {
  id: string;
  name: string;
  aktorType: AktorType;
  erAktiv: boolean;
}

export interface SelectedAktor {
  id: string;
  name: string;
  access: Access;
  roles: RoleType[];
  aktorType: AktorType;
}

export interface Person {
  navn: string;
  fodselsnummer: string;
}

export interface Article {
  id: string;
  title: string;
  ingress: string;
  imageUrl: string;
  bgColor: string | null;
  link: string;
}

export interface ProblemDetails {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
}

export enum RoleType {
  Auditor = 'Auditor',
}

export interface Access {
  surcharge: boolean;
}

export interface FinnAktorerResponse {
  identifikator: string;
  navn: string;
  aktortype: 'person' | 'organisasjon';
  erAktiv: boolean;
}

export interface HentAktorResponse {
  identifikator: string;
  navn: string;
  tilganger: string[];
  roller: string[];
  aktortype: 'person' | 'organisasjon';
}
