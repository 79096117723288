import { FC, Fragment } from 'react';
import { Grid, Box, Typography, styled } from 'enova-frontend-components';
import { useTranslation } from 'react-i18next';

import { getTermInMonths } from '../utility/utils';
import { SurchargeStatusType } from '../types';

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.greys.grey4};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.greys.grey4)};
  border-radius: 5px 5px 0 0;
`;

interface SurchargeHeaderProps {
  year: string;
  sequence: number;
  statusType: SurchargeStatusType;
  version: number | null;
}

const SurchargeHeader: FC<SurchargeHeaderProps> = ({
  year,
  sequence,
  statusType,
  version,
}) => {
  const { t } = useTranslation();

  const getStatusText = () => {
    if (statusType == SurchargeStatusType.Draft) {
      return t('surcharge.reporting.draft');
    }
    if (statusType == SurchargeStatusType.InProgress) {
      return t('surcharge.reporting.inProgress');
    }
    if (statusType == SurchargeStatusType.Sent) {
      return t('surcharge.reporting.sent');
    }
    return '';
  };

  return (
    <StyledBox>
      <Grid container spacing={2} padding={1} paddingLeft={2}>
        <Grid item xs={'auto'}>
          <Typography>{t('surcharge.reporting.workingOn')}:</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography bold>{t('surcharge.reporting.term')}:</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography>
            {sequence}. {t('surcharge.reporting.term')} {year} -{' '}
            {getTermInMonths(t, sequence)}
          </Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography bold>{t('surcharge.reporting.status')}:</Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Typography>{getStatusText()}</Typography>
        </Grid>
        {version ? (
          <Fragment>
            <Grid item xs={'auto'}>
              <Typography bold>{t('surcharge.reporting.version')}:</Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <Typography>{version}</Typography>
            </Grid>
          </Fragment>
        ) : null}
      </Grid>
    </StyledBox>
  );
};

export { SurchargeHeader };
