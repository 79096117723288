import React, { FC, ElementType } from 'react';
import { LinkProps } from 'enova-frontend-components';

import FooterLink from './footerLink';

type SoMeLinkProps = LinkProps & {
  logo: ElementType;
};

const SoMeLink: FC<SoMeLinkProps> = ({ children, logo: SoMeLogo, ...rest }) => (
  <FooterLink className="d-inline-flex ai-center" {...rest}>
    <SoMeLogo className="mr-2" />
    {children}
  </FooterLink>
);
export default SoMeLink;
