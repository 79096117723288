export enum AppRoute {
  START,
  LOGIN = 'login',
  LOGOUT = 'logout',
  SURCHARGE = 'surcharge',
  ARTICLE = 'article',
}

export const AppRoutes = {
  [AppRoute.LOGIN]: `/${AppRoute.LOGIN}`,
  [AppRoute.LOGOUT]: `/${AppRoute.LOGOUT}`,
  [AppRoute.START]: '/',
  [AppRoute.ARTICLE]: `/${AppRoute.ARTICLE}`,
};

export const AuthorizedAppRoutes = {
  ...AppRoutes,
  [AppRoute.SURCHARGE]: `/${AppRoute.SURCHARGE}`,
  [AppRoute.LOGOUT]: `/${AppRoute.LOGOUT}`,
};

export const camelize = (str: string): string =>
  str
    .replace('-', ' ')
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      +match === 0
        ? ''
        : index === 0
        ? match.toLowerCase()
        : match.toUpperCase()
    );
