import { api } from '../../utils/apiClient';

import {
  Grunnlagsdata,
  InvoiceDto,
  MetadataDto,
  RapportAr,
  RapporterNettariff,
  ReportDto,
  SigningRequest,
} from './types';

export const getNettariffMetadata = async (): Promise<MetadataDto> => {
  const url = '/nettariff/metadata';
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't fetch nettariff metadata");
  }

  return data;
};

export const getAvailableTerminer = async (
  organizationNumber: string,
  years: string[]
): Promise<RapportAr[]> => {
  const url = `/nettariff/${organizationNumber}/terminer?${years
    .map((y) => `ar=${y}`)
    .join('&')}`;
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't fetch available terminer");
  }

  return data;
};

export const getReportedData = async (
  organizationNumber: string,
  year: string,
  sequence: number
): Promise<ReportDto[]> => {
  const url = `/nettariff/${organizationNumber}/terminer/${year}/${sequence}`;
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't surcharge data");
  }

  return data;
};

export const getGetReceipt = async (
  organizationNumber: string,
  year: string,
  sequence: number,
  version: number
): Promise<InvoiceDto> => {
  const url = `/nettariff/${organizationNumber}/terminer/${year}/${sequence}/${version}/kvittering`;
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't get receipt");
  }

  return data;
};

export const reportKwh = async (report: RapporterNettariff): Promise<void> => {
  const url = `/nettariff/${report.organisasjonsnummer}/terminer/${report.ar}/${report.sekvens}`;

  await api.post(url, report).catch((error) => {
    throw new Error(error.response);
  });

  return Promise.resolve();
};

// Auditing
export const getGrunnlagsdataRevisorattest = async (
  organizationNumber: string,
  year: string
): Promise<Grunnlagsdata> => {
  const url = `/nettariff/${organizationNumber}/revisorattest/${year}/grunnlag`;
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't get auditor base data");
  }

  return data;
};

export const attest = async (
  organizationNumber: string,
  year: string
): Promise<void> => {
  const url = `/nettariff/${organizationNumber}/revisorattest/${year}`;
  await api.post(url, {}).catch((error) => {
    throw new Error(error.response);
  });

  return Promise.resolve();
};

export const sign = async (signingRequest: SigningRequest): Promise<void> => {
  const url = `/nettariff/${signingRequest.organisasjonsnummer}/revisorattest/${signingRequest.ar}/signer`;
  const response = await api.post(url, {}).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't get auditor base data");
  }

  return data;
};

export const completeSigning = async (request: {
  id: string;
  token: string;
}): Promise<void> => {
  const url = `/nettariff/revisorattest/signer/fullfor`;
  await api.post(url, request).catch((error) => {
    throw new Error(error.response);
  });

  return Promise.resolve();
};

export const cancelSigning = async (request: {
  id: string;
  token: string;
}): Promise<void> => {
  const url = `/nettariff/revisorattest/signer/avbryt`;
  await api.post(url, request).catch((error) => {
    throw new Error(error.response);
  });

  return Promise.resolve();
};

export const errorSigning = async (request: {
  id: string;
  token: string;
}): Promise<void> => {
  const url = `/nettariff/revisorattest/signer/feil`;
  await api.post(url, request).catch((error) => {
    throw new Error(error.response);
  });

  return Promise.resolve();
};
