import { Box, Button } from 'enova-frontend-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RouterLink from '../../../../components/routerLink';
import useAuthentication from '../../../../hooks/useAuthentication';

import { ViewActionTypography } from './styled';

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const authentication = useAuthentication();

  return (
    <Box paddingTop={8}>
      <ViewActionTypography variant="body1">
        {t('surcharge.article.login')}
      </ViewActionTypography>
      <Button
        variant="primary"
        onClick={() => authentication.signIn()}
        navigation
      >
        {t('surcharge.article.loginButton')}
      </Button>
    </Box>
  );
};

export const ReportView: FC = () => {
  const { t } = useTranslation();
  return (
    <Box paddingTop={8}>
      <ViewActionTypography variant="body1">
        {t('surcharge.article.goToTariff')}
      </ViewActionTypography>
      <RouterLink
        to={{
          pathname: '/surcharge',
        }}
      >
        <Button variant="primary" navigation>
          {t('surcharge.article.goToTariffButton')}
        </Button>
      </RouterLink>
    </Box>
  );
};

export const SelectOrganization: FC = () => {
  const { t } = useTranslation();
  return (
    <Box paddingTop={8}>
      <ViewActionTypography variant="body1">
        {t('surcharge.article.selectOrganization')}
      </ViewActionTypography>
    </Box>
  );
};
