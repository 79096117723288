import { FC, Fragment, useCallback, useMemo } from 'react';
import {
  Grid,
  Box,
  MainContentBox,
  Table,
  Paper,
  Typography,
  Loader,
  Button,
  useSnack,
} from 'enova-frontend-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { instanceOf, ProblemDetails } from '../../../../utils/types';
import { useSigning } from '../../hooks/useSigning';
import { useGetGrunnlagsdataRevisorattest } from '../../hooks/useGetGrunnlagsdataRevisorattest';

import { SigningValues } from './types';
import { getTableData, getTableHead } from './utils';
import AuditingHeader from './components/auditingHeader';

const SigningPage: FC = () => {
  const location = useLocation();

  const values = location.state as SigningValues;
  const { year, organizationName, organizationNumber } = values;

  const { addSnack } = useSnack();
  const { sign, isLoading: isSigningLoading } = useSigning();
  const { t } = useTranslation();
  const {
    auditorBasis,
    isLoading: isAudirbasisLoading,
  } = useGetGrunnlagsdataRevisorattest(organizationNumber, year);

  const heads = useMemo(() => getTableHead(t), [t]);
  const rows = useMemo(() => {
    if (!isAudirbasisLoading && auditorBasis != undefined) {
      return getTableData(t, auditorBasis);
    }
    return [];
  }, [isAudirbasisLoading, auditorBasis]);

  const onSign = useCallback(() => {
    sign(
      { organisasjonsnummer: organizationNumber, ar: year },
      {
        onSuccess: (data) => {
          if (instanceOf<{ url: string }>(data)) {
            window.location.href = data.url;
          }
        },
        onError: (err) => {
          if (instanceOf<ProblemDetails>(err)) {
            if (err.status === 403) {
              addSnack(t('surcharge.signing.failed.notAuthorized'), {
                variant: 'error',
              });
              return;
            }
            addSnack(t('surcharge.signing.failed.default'), {
              variant: 'error',
            });
          }
        },
      }
    );
  }, [values, sign]);

  return (
    <Fragment>
      <MainContentBox>
        <AuditingHeader year={year} attested={true} />
        <Paper shadow="small" style={{ width: '100%' }}>
          <Box padding={3} paddingTop={5}>
            <Typography paragraph variant="h1">
              {t('surcharge.signing.header')} {year}
            </Typography>
            <Typography paragraph variant="h4">
              {t('surcharge.signing.name')} : {organizationName}
            </Typography>
            <Typography paragraph variant="h4">
              {t('surcharge.signing.organizationNumber')} : {organizationNumber}
            </Typography>
            <Typography paragraph>{t('surcharge.signing.ingress')}</Typography>
            <Typography paragraph>{t('surcharge.signing.ingress2')}</Typography>
            <Box>
              {isAudirbasisLoading ? (
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  p={4}
                >
                  <Loader color="inherit" size="small" />
                </Box>
              ) : (
                <Fragment>
                  <Table
                    alternating
                    dense
                    disablePagination
                    headCells={heads}
                    rows={rows}
                    shadow="none"
                  />
                  <Grid container spacing={4} paddingTop={5}>
                    <Grid item xs={12} sm="auto">
                      <RouterLink
                        to={{
                          pathname: '/surcharge/overview',
                        }}
                      >
                        <Button variant="secondary" leftIcon="close">
                          {t('cancel')}
                        </Button>
                      </RouterLink>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <Button
                        variant="primary"
                        rightIcon="arrow_right"
                        disabled={isSigningLoading}
                        onClick={onSign}
                      >
                        {t('surcharge.signing.confirmAndSend')}
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Box>
          </Box>
        </Paper>
      </MainContentBox>
    </Fragment>
  );
};

export { SigningPage };
