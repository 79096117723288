export interface SurchargeReport {
  invoicedKwh: number;
  organizationNumber: string;
}

export enum SurchargeStatusType {
  Draft = 1,
  InProgress,
  Sent,
}

export interface RapportAr {
  ar: string;
  attestert: boolean;
  terminer: Termin[];
  signert: boolean;
}

export interface Termin {
  ar: string;
  sekvens: number;
  erApen: boolean;
  navn: string;
  frist: string;
  harRapportertNettariff: boolean;
  versjon: number;
}

export interface InvoiceDto {
  innhold: string;
}

export interface ReportDto {
  organisasjonsnummer: string;
  versjon: number;
  kilowattimer: number;
  unikeFakturerteMalepunktIder: number;
  totaltUnikeMalepunktIder: number;
  fakturertForMalepunktIder: number;
  datoOpprettet: string;
  totaltPaslag: number;
}

export interface Grunnlagsdata {
  ar: string;
  grunnlag: Grunnlag[];
}

export interface Grunnlag {
  ar: string;
  sekvens: number;
  kilowattimer: number;
  fakturertForKilowattimer: number;
  unikeFakturerteMalepunktIder: number;
  fakturertForMalepunktIder: number;
  totaltPaslag: number;
}

export interface MetadataDto {
  paslagForKWh: number;
  paslagForMalepunktId: number;
}

export type ReportValues = {
  organizationNumber: string;
  organizationName: string;
  year: string;
  sequence: number;
  invoicedKwhPrivateHouseholds: number;
  noOfUniqueIdsInNetwork: number;
  noOfUniqueIdsInvoiced: number;
  invoicedKwh: number;
  amountInvoiced: number;
  sumInvoicedPreviously: number;
  deadline: string;
  isCorrection: boolean;
  previouslyReported: number;
};

export type ReportProps = {
  selectedValues: ReportValues;
  metadata: MetadataDto | undefined;
};

export interface RapporterNettariff {
  organisasjonsnummer: string;
  ar: string;
  sekvens: number;
  kilowattimer: number;
  unikeMalepunktIderFakturert: number;
  totaltUnikeMalepunktIder: number;
  fakturertForMalepunktIDer: number;
}

export interface RapportertNettariff {
  invoicedKwhPrivateHouseholds: number;
  year: string;
  sequence: number;
  noOfUniqueIdsInvoiced: number;
  noOfUniqueIdsInNetwork: number;
  invoicedAmountForIds: number;
}

export interface SigningRequest {
  organisasjonsnummer: string;
  ar: string;
}
