import { createContext, Dispatch, SetStateAction } from 'react';

import { SelectedAktor } from '../../utils/types';

export type AppContextValues = {
  setSelectedAktor: Dispatch<SetStateAction<SelectedAktor | null>>;
  selectedAktor: SelectedAktor | null;
};

const AppContext = createContext<AppContextValues>({
  setSelectedAktor: () => true,
  selectedAktor: {} as SelectedAktor,
});

export default AppContext;
