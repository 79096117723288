import { FC, Fragment } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Layout from './components/layout';
import LoginScreen from './screens/login';
import SurchargeScreen from './modules/surcharge/screens';
import { AppRoute, AuthorizedAppRoutes } from './utils/navigation';
import { ContentPage } from './modules/content/screens';
import PageNotFound from './screens/pageNotFound';
import LoginDialog from './screens/login/loginDialog';
import LogoutScreen from './screens/logout';

export const Authenticated: FC = () => {
  const { t } = useTranslation();

  const defaultTitle = [t('appName'), t('companyName')].join(' | ');

  // PageNotFoundScreen uses custom main props in layout
  const pageNotFound = !useRouteMatch({
    path: [...Object.values(AuthorizedAppRoutes), '/'],
    exact: true,
  });

  return (
    <Fragment>
      <LoginDialog />
      <Helmet
        defaultTitle={defaultTitle}
        titleTemplate={`%s | ${defaultTitle}`}
      />

      <Layout
        fullWidth={true}
        MainProps={
          pageNotFound
            ? {
                ContainerProps: { className: 'd-flex' },
                className: 'd-flex f-fill',
              }
            : undefined
        }
      >
        <Switch>
          <Route
            component={SurchargeScreen}
            path={AuthorizedAppRoutes[AppRoute.SURCHARGE]}
          />
          <Route
            component={LoginScreen}
            path={AuthorizedAppRoutes[AppRoute.LOGIN]}
          />
          <Route
            component={LogoutScreen}
            path={AuthorizedAppRoutes[AppRoute.LOGOUT]}
          />
          <Route
            component={ContentPage}
            path={AuthorizedAppRoutes[AppRoute.ARTICLE]}
          />
          <Route component={ContentPage} exact path="/" />
          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </Fragment>
  );
};
