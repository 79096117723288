import { api } from './utils/apiClient';
import { FinnAktorerResponse, HentAktorResponse } from './utils/types';

export const getAktorer = async (): Promise<FinnAktorerResponse[]> => {
  const url = '/aktorer';
  const response = await api.get(url).catch((error) => {
    throw new Error(error.response);
  });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't find aktorer");
  }

  return data;
};

export const loadAktor = async (
  identifikator: string,
  aktortype: 'person' | 'organisasjon'
): Promise<HentAktorResponse> => {
  const url = '/aktorer';
  const response = await api
    .post(url, { identifikator, aktortype })
    .catch((error) => {
      throw new Error(error.response);
    });

  const { data } = response;

  if (!data) {
    throw new Error("Couldn't find aktor");
  }

  return data;
};
