import { FC, Fragment } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Layout from './components/layout';
import LoginScreen from './screens/login';
import { AppRoute, AppRoutes } from './utils/navigation';
import { ContentPage } from './modules/content/screens';
import PageNotFound from './screens/pageNotFound';
import LogoutScreen from './screens/logout';

export const Public: FC = () => {
  const { t } = useTranslation();

  const defaultTitle = [t('appName'), t('companyName')].join(' | ');

  const pageNotFound = !useRouteMatch({
    path: [...Object.values(AppRoutes), '/'],
    exact: true,
  });

  return (
    <Fragment>
      <Helmet
        defaultTitle={defaultTitle}
        titleTemplate={`%s | ${defaultTitle}`}
      />

      <Layout
        fullWidth={true}
        MainProps={
          pageNotFound
            ? {
                ContainerProps: { className: 'd-flex' },
                className: 'd-flex f-fill',
              }
            : undefined
        }
      >
        <Switch>
          <Route component={LoginScreen} path={AppRoutes[AppRoute.LOGIN]} />
          <Route component={LogoutScreen} path={AppRoutes[AppRoute.LOGOUT]} />
          <Route component={ContentPage} path={AppRoutes[AppRoute.ARTICLE]} />
          <Route component={ContentPage} exact path="/" />
          <Route component={PageNotFound} />
        </Switch>
      </Layout>
    </Fragment>
  );
};
