import { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { EnovaContextProvider } from 'enova-frontend-components';
import { QueryClientProvider } from 'react-query';

import AppDataProvider from './hooks/useAppData/provider';
import { AuthenticationProvider } from './hooks/useAuthentication/provider';
import useAuthentication from './hooks/useAuthentication';
import { queryClient } from './utils/react-query';
import useLocalStorage, { reservedKeys } from './hooks/useLocalStorage';
import i18n from './i18n';
import { authenticationConfig, locizeConfig } from './config';
import { ApiProvider } from './components/apiProvider';
import { Public } from './public';
import { Authenticated } from './Authenticated';
import { Locale } from './types';

const AppContent: FC = () => {
  const { isAuthenticated } = useAuthentication();
  if (isAuthenticated) {
    return (
      <QueryClientProvider client={queryClient}>
        <ApiProvider>
          <AppDataProvider>
            <BrowserRouter>
              <Authenticated />
            </BrowserRouter>
          </AppDataProvider>
        </ApiProvider>
      </QueryClientProvider>
    );
  }

  return (
    <BrowserRouter>
      <Public />
    </BrowserRouter>
  );
};

const App: FC = () => {
  const [envLng] = useLocalStorage<Locale>(
    reservedKeys.lng.env,
    navigator.language as Locale
  );

  const [lng] = useLocalStorage<Locale>(
    reservedKeys.lng.app,
    navigator.language as Locale
  );

  useEffect(() => {
    i18n.changeLanguage('nb-NO');
    // if (!lng) {
    //   setEnvLng((process.env.REACT_APP_LNG || navigator.language) as Locale);
    //   setLng((process.env.REACT_APP_LNG || navigator.language) as Locale);
    //   i18n.changeLanguage(process.env.REACT_APP_LNG || navigator.language);
    // } else i18n.changeLanguage(lng as string);
  }, [envLng, lng, locizeConfig.defaultLanguage]);

  return (
    <EnovaContextProvider locale={lng}>
      <AuthenticationProvider {...authenticationConfig}>
        <AppContent />
      </AuthenticationProvider>
    </EnovaContextProvider>
  );
};

export default App;
